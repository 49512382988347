import $ from 'jquery'
import 'slick-carousel'
import BaseDOMModule from '../core/modules/BaseDOMModule'
import { loadImage } from '../utils/lazyImages'

export default class TeaserCarousel extends BaseDOMModule {
  static domEvents = {
    init: 'setTeasersSameHeight',
    breakpoint: 'setTeasersSameHeight'
  }

  hasLoadedImageElements = false

  getSlickOptions(itemCount) {
    const options = {
      arrows: true,
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,

      prevArrow: this.$('[data-arrow-previous]'),
      nextArrow: this.$('[data-arrow-next]'),

      mobileFirst: true,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 1125,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 1330,
          settings: {
            slidesToShow: 4
          }
        }
      ]
    }

    if (itemCount === 4) {
      options.responsive.push({
        breakpoint: 1330,
        settings: {
          slidesToShow: 4
        }
      })
    }

    return options
  }

  constructor(...args) {
    super(...args)
    const itemCount = this.$('.teaser-layout__item').length

    this.initializeSlickSlider(itemCount)
  }

  initializeSlickSlider(itemCount) {
    const $sliderTarget = this.$(this.options.itemContainerSelector)
    $sliderTarget.slick(this.getSlickOptions(itemCount))

    // Handle issue with IntersectionObserver on some mobile devices
    $sliderTarget.on('beforeChange', event => {
      if (!this.hasLoadedImageElements) {
        const $lazyImages = $(event.target).find('img[data-src]')
        $lazyImages.each((_, imageElement) => loadImage(imageElement))
        this.hasLoadedImageElements = true
      }
    })
  }

  setTeasersSameHeight() {
    const $items = this.$(this.options.itemSelector)

    const largestHeight = $items
      .map((_, item) => $(item).height())
      .get()
      .reduce((a, b) => (a > b ? a : b), 0)
    if (
      $items
        .children()
        .children()
        .children('.cta-link').length === 0
    ) {
      $items.children().addClass('no-cta-link')
      $items.height($items.height() + 10)
    } else {
      $items.height('450px')
    }
  }
}

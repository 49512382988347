import BaseDOMModule from '../core/modules/BaseDOMModule'
import DialogService from '../services/DialogService'

export default class DialogLink extends BaseDOMModule {
  static domEvents = {
    click: 'onClick'
  }

  onClick(event) {
    event.preventDefault()

    const { title, body, actionText, closeLabel } = this.options
    DialogService.openDialog({
      title,
      body,
      actionText,
      closeLabel
    })
  }
}

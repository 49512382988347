import BaseDOMModule from '../../core/modules/BaseDOMModule'
import CartService from '../../services/commerce/CartService'

export default class GoToCheckout extends BaseDOMModule {
  static domEvents = {
    
  }

  constructor(...args) {
    super(...args)

    CartService.on(CartService.CART_UPDATED, this.onCartUpdated.bind(this))
    //CartService.getCurrentCartState() // is fired in HeaderCart.js
  }

  onCartUpdated(cart) {
    const hasItemsInCart = cart.lineItems.length > 0
    if (hasItemsInCart) {
      this.$element.removeClass('pdp__checkout-button--disabled')
    } else {
      this.$element.addClass('pdp__checkout-button--disabled')
    }
  } 

}

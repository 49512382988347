const { NordLock } = window

class AnalyticsCheckoutStep {
  trackCheckoutStep(step) {
    if (window.dataLayer) {
      let products = ''

      if (
        NordLock &&
        NordLock.pageModels &&
        NordLock.pageModels.initialCart
      ) {
        products = NordLock.pageModels.initialCart.lineItems.map(item => {
          return {
            name: item.productName,
            id: item.code,
            price: item.placedPriceAmount,
            quantity: item.quantity
          }
        })
      }

      window.dataLayer.push({
        event: 'EEcheckout',
        ecommerce: {
          checkout: {
            actionField: {
              step: step,
              action: 'checkout'
            },
            products: products ?? ''
          }
        }
      })
    }
  }
}

export default new AnalyticsCheckoutStep()

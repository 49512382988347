import BaseDOMModule from '../core/modules/BaseDOMModule'
import Console from '../core/utils/Console'

export default class WebShare extends BaseDOMModule {
  static domEvents = {
    'click [data-share]': 'onShareClick'
  }

  constructor(...args) {
    super(...args)

    this.checkSupport()
  }

  checkSupport() {
    const isSupported = !!navigator.share
    this.$element.toggleClass(this.options.supportedClass, isSupported)
  }

  async onShareClick() {
    Console.debug('Share clicked')

    const url = this.getShareUrl()
    const title = this.getShareTitle()
    const text = this.getShareText()

    try {
      await navigator.share({ url, title, text })

      Console.debug('Shared through Web API')
    } catch (error) {
      Console.error('Failed to share', error)
    }
  }

  getShareUrl() {
    let { shareUrl } = this.options

    if (shareUrl) {
      return shareUrl
    }

    const canonicalElement = document.querySelector('link[rel=canonical]')
    if (canonicalElement) {
      return canonicalElement.href
    }

    return document.location.href
  }

  getShareTitle() {
    const { shareTitle } = this.options

    if (shareTitle) {
      return shareTitle
    }

    return document.title
  }

  getShareText() {
    const { shareText } = this.options

    if (shareText) {
      return shareText
    }

    return undefined
  }
}

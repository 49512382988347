import BaseService from '../core/services/BaseService'
import ClientData from './ClientData'

class FilterService extends BaseService {
  getDocumentTree() {
    return ClientData.getPageModelData(`documentDownload.documentTree`)
  }

  getPageListingTree(dataId) {
    return ClientData.getPageModelData(dataId)
  }

  getFilterNameByKey(targetTree, filterKey) {
    const tree = this.crawlFindTree(
      targetTree,
      tree => tree.root.key.toString() === filterKey
    )

    if (!tree) return ''

    return tree.root.value
  }

  getPageFilterNameByKey(dataId, filterKey) {
    return this.getFilterNameByKey(this.getPageListingTree(dataId), filterKey)
  }

  getDocumentFilterNameByKey(filterKey) {
    if (filterKey && filterKey.startsWith('Product:')) {
      return filterKey.slice(filterKey.indexOf(':') + 1)
    }
    return this.getFilterNameByKey(this.getDocumentTree(), filterKey)
  }

  *flattenTreeAssets(tree, filterCallback = x => true) {
    yield* (tree.entries || []).filter(filterCallback)

    for (let i = 0; i < (tree.subTrees || []).length; i++) {
      yield* [...this.flattenTreeAssets(tree.subTrees[i], filterCallback)]
    }
  }

  createFilterObject(trees, level) {
    return {
      level,
      list: (trees || []).map(x => ({
        value: x.root.key,
        text: x.root.value
      }))
    }
  }

  *generateFilters(
    tree,
    filters = [],
    filterCallback = xtree => true,
    level = 0
  ) {
    const cleanFilters = filters.filter(x => x)
    const currentFilterKey = cleanFilters[0]

    // If no subtrees exit function
    if (!tree.subTrees || tree.subTrees.length < 1) return

    yield this.createFilterObject(tree.subTrees.filter(filterCallback), level)

    // Try find subtree with current filter
    const subTree = tree.subTrees.find(
      x => x.root.key.toString() === currentFilterKey
    )

    // If tree has more subtrees go through them as well
    if (subTree) {
      filters.shift()
      level = level + 1
      yield* this.generateFilters(subTree, filters, filterCallback, level)
    }
  }

  crawlFindTree(inputTree, findCallback, level = 0) {
    // If no callback or root level tree fits - return
    if (!findCallback || findCallback(inputTree, level)) {
      return inputTree
    }

    if (!inputTree.subTrees) return

    level = level + 1
    const result = inputTree.subTrees
      .map(subTree => this.crawlFindTree(subTree, findCallback, level))
      .reduce((acc, val) => acc.concat(val), [])
      .filter(x => x !== undefined)

    return result[0]
  }

  getTreeLevelByFilters(tree, filters = []) {
    const cleanFilters = filters.filter(x => x)
    const filterKey = cleanFilters[cleanFilters.length - 1]
    if (!filterKey) return tree

    return this.crawlFindTree(tree, (currentTree, level) => {
      return currentTree.root.key.toString() === filterKey
    })
  }
}

export default new FilterService()

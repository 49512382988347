import BaseService from '../../core/services/BaseService'
import CartService from './CartService'

class AnalyticsEcommerceService extends BaseService {
  constructor(...args) {
    super(...args)
    this.analytics = window.dataLayer || []

    CartService.on(CartService.CART_ITEM_ADDED, this.onCartItemAdded.bind(this))
    CartService.on(
      CartService.CART_ITEM_REMOVED,
      this.onCartItemRemoved.bind(this)
    )
    CartService.on(
      CartService.CART_ITEM_CHANGED_QUANTITY,
      this.onCartChangeQuantity.bind(this)
    )
  }

  analyticsAddItem(item, currency) {
    this.analytics.push({
      event: 'EEaddToCart',
      ecommerce: {
        currencyCode: currency.currencyCode,
        add: {
          products: [
            {
              id: item.code,
              name: item.productName,
              price: item.placedPriceAmount,
              quantity: item.quantity
            }
          ]
        }
      }
    })
  }

  onCartItemAdded(item, previousItem, { currency }) {
    this.analyticsAddItem(
      {
        ...item,
        quantity: item.quantity - ((previousItem || {}).quantity || 0)
      },
      currency
    )
  }

  onCartItemRemoved(item) {
    this.analytics.push({
      event: 'EEremoveFromCart',
      ecommerce: {
        remove: {
          products: [
            {
              id: item.code,
              name: item.productName,
              price: item.placedPriceAmount,
              quantity: item.quantity
            }
          ]
        }
      }
    })
  }

  onCartChangeQuantity(item, { currency, previousQuantity, newQuantity }) {
    if (newQuantity < previousQuantity) {
      this.onCartItemRemoved({
        ...item,
        quantity: previousQuantity - newQuantity
      })
    } else {
      this.analyticsAddItem(
        { ...item, quantity: newQuantity - previousQuantity },
        currency
      )
    }
  }
}

export default new AnalyticsEcommerceService()

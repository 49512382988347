import BaseDOMModule from '../core/modules/BaseDOMModule'

export default class Print extends BaseDOMModule {
  static domEvents = {
    'click [data-print-button-press]': 'printClick'
  }

  printClick(event, $element) {
    window.print()
  }
}

import BaseDOMModule from '../core/modules/BaseDOMModule'

const isSupported = 'objectFit' in document.documentElement.style

export default class FitImage extends BaseDOMModule {
  constructor(...args) {
    super(...args)

    if (isSupported) {
      return
    }

    this.applyFallbackObjectFit()
  }

  applyFallbackObjectFit() {
    const $imgElement = this.$('img')
    if ($imgElement.length === 0) {
      return
    }

    let $imgParent
    if (this.options.useClosestParent) {
      $imgParent = $imgElement.parent().closest(':not(picture)')
    }

    if (!$imgParent || $imgParent.length === 0) {
      $imgParent = this.$element
    }

    $imgParent.css('background-image', `url(${$imgElement.attr('src')})`)
    $imgParent.addClass('fit-image--is-polyfilled')
  }
}

import $ from 'jquery'

export function getNumberOfPages() {
  const pageHeightInPixels = 1123
  const pageBreakCount = $('.page-break').length
  const contentRect = document
    .getElementsByTagName('main')[0]
    .getBoundingClientRect()
  const contentPages = Math.round(contentRect.height / pageHeightInPixels)

  if (pageBreakCount == 0) {
    $('.manual-section').css('page-break-inside', 'avoid')
    return Math.ceil(contentPages)
  } else {
    const pageBreak = Math.floor(pageBreakCount * 0.5)

    return Math.ceil(contentPages + pageBreak)
  }
}

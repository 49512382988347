const { navigator, screen } = window
const { userAgent } = navigator

export const isIE = /MSIE|Trident/.test(userAgent)

export const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream

export const isButtonlessIOS =
  isIOS &&
  screen &&
  screen.availHeight >= 896 &&
  screen.availWidth >= 414 &&
  screen.availWidth < 768

export const supportsPassiveEventListeners = (() => {
  let supportsPassive = false
  try {
    const opts = Object.defineProperty({}, 'passive', {
      get() {
        supportsPassive = true
      }
    })
    window.addEventListener('testPassive', null, opts)
    window.removeEventListener('testPassive', null, opts)
  } catch (e) {
    /* Intentionally empty */
  }

  return supportsPassive
})()

export function addBrowserClassnameToRootElement() {
  const { documentElement } = document

  if (!documentElement.classList) {
    return
  }

  if (isIE) {
    documentElement.classList.add('ie')
  }

  if (isIOS) {
    documentElement.classList.add('ios')
  }

  if (isButtonlessIOS) {
    documentElement.classList.add('buttonless-ios')
  }
}

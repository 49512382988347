import BaseDOMModule from '../core/modules/BaseDOMModule'
import { setCookie, DEFAULT_PATH, DOMAIN } from '../core/utils/Cookies'
import { toggleBodyScroll } from '../services/GlobalDOM'

export default class CountrySelection extends BaseDOMModule {
  static domEvents = {
    'click [data-exit]': 'close',
    'click [data-visit-country]': 'onVisitCountryClick'
  }

  close() {
    this.$element.removeClass(this.options.activeClass)
    toggleBodyScroll(true)
  }

  show() {
    this.$element.addClass(this.options.activeClass)
    toggleBodyScroll(false)
  }

  onVisitCountryClick(event, $element) {
    setCookie(
      this.options.cookieName,
      $element.data('visitCountry'),
      Infinity,
      DEFAULT_PATH
    )
  }
}

import BaseDOMModule from '../core/modules/BaseDOMModule'

export default class Share extends BaseDOMModule {
  static domEvents = {
    'click [data-popup]': 'onPopupClick'
  }

  onPopupClick(event, $element) {
    const width = $element.data('width') || 600
    const height = $element.data('height') || 400
    const url = $element.data('url')
    const name = $element.data('name') || url

    this.popup(url, name, width, height)
  }

  popup(url, title, w, h) {
    var dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX
    var dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY

    var width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width
    var height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height

    var systemZoom = width / window.screen.availWidth
    var left = (width - w) / 2 / systemZoom + dualScreenLeft
    var top = (height - h) / 2 / systemZoom + dualScreenTop
    var newWindow = window.open(
      url,
      title,
      'scrollbars=yes' +
        ', width=' +
        w / systemZoom +
        ', height=' +
        h / systemZoom +
        ', top=' +
        top +
        ', left=' +
        left
    )

    if (window.focus) newWindow.focus()
  }
}

import $ from 'jquery'
import BaseDOMModule from '../../../core/modules/BaseDOMModule'
import { renderDataValueInElement } from '../../../core/utils/templateEngine'
import CheckoutService from '../../../services/commerce/CheckoutService'
import MarketService from '../../../services/commerce/MarketService'
import CartService from '../../../services/commerce/CartService'
import Console from '../../../core/utils/Console'
import AnalyticsCheckoutStep from '../../../services/commerce/AnalyticsCheckoutStep'

export default class CheckoutForm extends BaseDOMModule {
  static domEvents = {
    'click [data-order-button]': 'onFormSubmit',
    'blur [data-update-on-blur]': 'onInputBlurUpdate',
    'blur [data-input-sales-tax-number]': 'onSalesTaxNumberBlurUpdate',
    'change [data-input-tax-exempt]': 'onTaxExemptChange',
    'focus .text-input__field': 'onTextInputFocus',
    'blur [data-input-vat-number]': 'onVatBlurUpdate',
    'change [data-input-country]': 'onCountryChange',
    'change [data-input-shipping-method]': 'onShippingMethodChange',
    'change [data-payment-method]': 'onPaymentMethodChange',
    'change [data-user-type-input]': 'onUserTypeChange',
    'change [data-terms-checkbox]': 'onTermsCheckboxChange',
    'change [data-input-billing-address-checkbox]': 'onBillingAddressChange'
  }

  currentUpdate = null
  pendingUpdate = false
  hasLineitems = false
  $stateOptionTemplate = null

  analyticsDeliveryAddressFocus = false
  analyticsSubmitForm = false

  constructor(...args) {
    super(...args)

    MarketService.on(
      MarketService.MARKET_UPDATE,
      this.onMarketUpdate.bind(this)
    )

    CartService.on(CartService.CART_UPDATED, this.onCartUpdated.bind(this))

    this.hasLineitems = this.options.hasLineitems.toLocaleLowerCase() === 'true'
    this.handleUserTypeFields()
    this.handleOrderbutton()

    this.$stateOptionTemplate = this.$('[data-template="stateOption"]')
    this.$stateOptionTemplate.removeAttr('data-template')
    this.$stateOptionTemplate.remove()
  }

  onMarketUpdate({ countryCode }) {
    this.$('[data-input-country]').val(countryCode)
  }

  onCartUpdated(cart) {
    if (cart.lineItems.length <= 0) {
      this.$('button[type="submit"]').each((i, element) => {
        $(element).attr('disabled', true)
      })

      this.$('input').each((i, element) => {
        $(element).attr('disabled', true)
      })
    }
  }

  onBillingAddressChange(event) {
    const checkbox = event?.target
    const checked = checkbox.checked
    const containerClass = 'checkout-form-info__billing-address-container'
    const activeClass = `${containerClass}--active`

    const $container = $(`.${containerClass}`)

    if (checked) {
      $container.removeClass(activeClass)
      this.makeBillingFieldsRequired(false)
    } else {
      $container.addClass(activeClass)
      this.makeBillingFieldsRequired(true)
    }

    this.postUpdateToServer()
  }

  makeBillingFieldsRequired(required) {
    this.$(`[data-input-billing-first-name]`).prop('required', required)
    this.$(`[data-input-billing-last-name]`).prop('required', required)
    this.$(`[data-input-billing-address]`).prop('required', required)
    this.$(`[data-input-billing-postal-code]`).prop('required', required)
    this.$(`[data-input-billing-city]`).prop('required', required)
    this.$(`[data-input-billing-country]`).prop('required', required)

    if (this.options.isStateCountry.toLocaleLowerCase() === 'true') {
      this.$(`[data-input-billing-state]`).prop('required', required)
    }
  }

  async onFormSubmit(event) {
    if (!this.isFormValid()) {
      event.preventDefault()
      this.markAllErrorControls()
    } else {
      this.trackSubmitForm()
    }
  }

  trackSubmitForm() {
    if (!this.analyticsSubmitForm) {
      this.analyticsSubmitForm = true

      const submitFormStep = 3
      AnalyticsCheckoutStep.trackCheckoutStep(submitFormStep)
    }
  }

  onTextInputFocus(event, $targetInput) {
    this.trackDeliveryAddressFocus()

    $targetInput.parent().removeClass('error');
  }

  trackDeliveryAddressFocus() {
    if (!this.analyticsDeliveryAddressFocus) {
      this.analyticsDeliveryAddressFocus = true

      const deliveryAddressStep = 2
      AnalyticsCheckoutStep.trackCheckoutStep(deliveryAddressStep)
    }
  }

  onInputBlurUpdate(event, $targetInput) {
    if (this.hasChangedSinceUpdate($targetInput)) {
      this.postUpdateToServer()
    }
  }

  onUserTypeChange(event, $target) {
    this.handleUserTypeFields()
  }

  onCountryChange(event, $countrySelect) {
    const countryVal = $countrySelect.val()

    CheckoutService.changeCountry(
      countryVal,
      $countrySelect.children(':selected').text()
    )

    this.handleStateInputField(countryVal)
    this.postUpdateToServer()
  }

  onShippingMethodChange() {
    this.postUpdateToServer()
  }

  onVatBlurUpdate(event, $target) {
    const vatNumber = $target.val()
    this.postVatNumberToServer(vatNumber)
  }

  onSalesTaxNumberBlurUpdate(event, $target) {
    const salesTaxNumber = $target.val()
    this.postSalesTaxNumberToServer(
      salesTaxNumber,
      this.$('[data-input-tax-exempt]').is(':checked')
    )
  }

  onTaxExemptChange(event, $target) {
    this.postSalesTaxNumberToServer(
      this.$('[data-input-sales-tax-number]').val(),
      $target.is(':checked')
    )
  }

  onPaymentMethodChange(event, $target) {
    $target.parent().removeClass('error')

    // Set selected current payment method id
    this.$('input[name="PaymentMethodId"]').val($target.data('id'))
    this.$('input[name="SystemKeyword"]').val($target.data('systemKeyword'))
  }

  onTermsCheckboxChange(event) {
    this.handleOrderbutton()
  }

  handleStateInputField(country) {
    const hiddenClassname = 'checkout-form-info__field--hidden'

    // Get state input field
    const $inputStateField = this.$('[data-input-state]')
    const $inputStateParent = $inputStateField.closest(
      '.checkout-form-info__field'
    )

    const currentStates = CheckoutService.getStatesForCountry(country)

    if (currentStates) {
      // Populate with current states
      $inputStateField.empty()

      currentStates.forEach(state => {
        const template = this.$stateOptionTemplate.clone()
        renderDataValueInElement(template, '_text', state.key)
        renderDataValueInElement(template, '_attr', { value: state.value })

        $inputStateField.append(template)
      })

      // Make it visible
      $inputStateParent.removeClass(hiddenClassname)

      // Set as required
      $inputStateField.prop('required', true)
    } else {
      if (!$inputStateParent.hasClass(hiddenClassname)) {
        $inputStateParent.addClass(hiddenClassname)
        $inputStateField.val('')
        $inputStateField.removeAttr('required')
      }
    }
  }

  handleBusinessFields(currentUserType, country) {
    if (currentUserType !== 'business') {
      this.$('[data-user-type="business"]')
        .parent()
        .hide()

      //Make Business checkout fields non required
      this.$(`[data-input-vat-number]`).prop('required', false)
      this.$(`[data-input-company]`).prop('required', false)
      this.$(`[data-input-sales-tax-number]`).prop('required', false)

      this.$('[data-same-billing-address]').hide()

      this.$('[data-input-vat-number]').val('')
      this.$('[data-input-sales-tax-number]').val('')
      this.$('[data-input-tax-exempt]').prop('checked', false)
    } else {
      const salesTaxCountry = CheckoutService.getSalesTaxCountries().find(
        x => x.country === country
      )

      this.$(`[data-input-company]`).prop('required', true)
      this.$('[data-same-billing-address]').show()

      this.$('[data-business-field-type="any"]')
        .parent()
        .show()

      if (salesTaxCountry) {
        this.$(`[data-input-sales-tax-number]`).prop('required', true)
        this.$('[data-input-sales-tax-number]')
          .parent()
          .show()

        if (salesTaxCountry.allowTaxExemption) {
          this.$('[data-input-tax-exempt]')
            .parent()
            .show()
        }
      } else {
        this.$(`[data-input-vat-number]`).prop('required', true)
        this.$('[data-business-field-type="vat"]')
          .parent()
          .show()
      }
    }

    this.postUpdateToServer()
  }

  postVatNumberToServer(vatNumber) {
    CheckoutService.setVatNumber(vatNumber).then(() => {
      this.$('[data-input-vat-number]')
        .parent()
        .removeClass('error');
    }).catch(() => {
      this.postUpdateToServer()
      this.$('[data-input-vat-number]')
        .parent()
        .addClass('error');
    })
  }

  postSalesTaxNumberToServer(salesTaxNumber, requestTaxExemption) {
    CheckoutService.setSalesTaxNumber(
      salesTaxNumber,
      requestTaxExemption
    ).catch(error => {
      // this.postUpdateToServer()
      this.$('[data-input-sales-tax-number]')
        .parent()
        .addClass('error')
    })
  }

  postUpdateToServer() {
    if (this.currentUpdate) {
      this.pendingUpdate = true
      return
    }

    const deliveryDetails = this.getFormData()

    this.currentUpdate = CheckoutService.updateDeliveryDetails(deliveryDetails)
      .then(() => {})
      .catch(event => {
        Console.error(event.responseJSON.message)
      })
      .finally(() => {
        this.currentUpdate = null
        this.handleOrderbutton()
        if (this.pendingUpdate) {
          this.pendingUpdate = false
          this.postUpdateToServer()
        }
      })
  }

  getFormData() {
    const sameBillingAddressValue = this.$(
      '[data-input-billing-address-checkbox]:checked'
    ).val()
    const currentUserType = this.$('[data-user-type-input]:checked').val()

    const sameBillingAddress = sameBillingAddressValue == null ? false : true
    const shouldIncludeDifferentBillingAddress =
      !sameBillingAddress && currentUserType === 'business'

    return {
      addressLine1: this.getFormElementValue('address-1'),
      addressLine2: this.getFormElementValue('address-2'),
      city: this.getFormElementValue('city'),
      state: this.getFormElementValue('state'),
      postalCode: this.getFormElementValue('postal-code'),
      countryCode: this.getFormElementValue('country'),
      firstName: this.getFormElementValue('first-name'),
      lastName: this.getFormElementValue('last-name'),
      email: this.getFormElementValue('email'),
      phoneNumber: this.getFormElementValue('phone-number'),
      company: this.getFormElementValue('company'),
      shippingMethod: this.$('[data-input-shipping-method]:checked').val(),
      userType : currentUserType,
      sameBillingAddress: currentUserType === 'business' && sameBillingAddress,
      billingFirstName: shouldIncludeDifferentBillingAddress
        ? this.getFormElementValue('billing-first-name')
        : '',
      billingLastName: shouldIncludeDifferentBillingAddress
        ? this.getFormElementValue('billing-last-name')
        : '',
      billingAddressLine: shouldIncludeDifferentBillingAddress
        ? this.getFormElementValue('billing-address')
        : '',
      billingAddressLine2: shouldIncludeDifferentBillingAddress
        ? this.getFormElementValue('billing-address-2')
        : '',
      billingPostalCode: shouldIncludeDifferentBillingAddress
        ? this.getFormElementValue('billing-postal-code')
        : '',
      billingCity: shouldIncludeDifferentBillingAddress
        ? this.getFormElementValue('billing-city')
        : '',
      billingState: shouldIncludeDifferentBillingAddress
        ? this.getFormElementValue('billing-state')
        : '',
      billingCountryCode: shouldIncludeDifferentBillingAddress
        ? this.getFormElementValue('billing-country')
        : '',
      billingCompany: shouldIncludeDifferentBillingAddress
        ? this.getFormElementValue('billing-company')
        : ''
    }
  }

  handleUserTypeFields() {
    const currentUserType = this.$('[data-user-type-input]:checked').val()
    const selectedCountry = this.$('[data-input-country]').val()
    
    this.handleBusinessFields(currentUserType, selectedCountry)
  }

  handleOrderbutton() {
    const checked = this.$('[data-terms-checkbox]:checked').length > 0
    const shippingMethod = this.$('[data-input-shipping-method]:checked').val()

    this.$('[data-order-button]').prop(
      'disabled',
      !checked || !this.hasLineitems || !shippingMethod
    )
  }

  getFormElementValue(dataName) { 
    return this.$(`[data-input-${dataName}]`).val();
  }

  hasChangedSinceUpdate($formElement) {
    return true
  }

  getAllInvalidElements() {
    const $requiredElements = this.$('[required]')
    const requiredElements = $requiredElements.toArray()
    return requiredElements.filter(element => !this.isElementValid(element))
  }

  isFormValid() {
    return this.getAllInvalidElements().length <= 0
  }

  isElementValid(formElement) {
    if (formElement.attributes['data-input-email-repeat']) {
      // formElement is confirm email input

      if (!formElement.value) return false
      return this.getFormElementValue('email') === formElement.value
    }

    if (formElement.attributes['data-input-vat-number']) {
      let isValid = !this.$('[data-input-vat-number]').parent().hasClass('error') && formElement.validity.valid !== false;
      return isValid;
    }
    return formElement.validity.valid !== false
  }

  markAllErrorControls() {
    const invalidElements = this.getAllInvalidElements()

    invalidElements.forEach(element =>
      element.parentElement.classList.add('error')
    )
  }
}

import 'slick-carousel'
import BaseDOMModule from '../core/modules/BaseDOMModule'
import { loadImage } from '../utils/lazyImages'

export default class HeroCarousel extends BaseDOMModule {
  static domEvents = {
    init: 'setHeroCarouselHeights'
  }

  static windowEvents = {
    resize: 'setHeroCarouselHeights'
  }

  constructor(...args) {
    super(...args)

    this.checkRenderContext()
    this.setHeroCarouselHeights()
    this.initializeSlickSlider()
  }

  checkRenderContext() {
    if (this.$element[0]?.parentElement?.tagName !== 'MAIN') {
      this.$element[0].classList.add('hero-carousel--content-area')
    }
  }

  hasLoadedImageElements = false

  getSlickOptions(epiOptions) {
    const options = {
      dots: true,
      infinite: true,

      slidesToShow: 1,
      slidesToScroll: 1,

      prevArrow: this.$('[data-arrow-previous]'),
      nextArrow: this.$('[data-arrow-next]'),

      autoplay: epiOptions.autoplay,
      autoplaySpeed: epiOptions.autoplaySpeed,
      speed: 750,

      mobileFirst: true
    }

    return options
  }

  initializeSlickSlider() {
    const $sliderTarget = this.$(this.options.containerSelector)

    const epiOptions = {
      autoplay: this.options.disableAutoplay === 'False' ? true : false,
      autoplaySpeed: this.options.playerSpeed || 5000
    }

    $sliderTarget.on('beforeChange', event => {
      if (!this.hasLoadedImageElements) {
        const $lazyImages = $(event.target).find('img[data-src]')
        $lazyImages.each((_, imageElement) => loadImage(imageElement))
        this.hasLoadedImageElements = true
      }
    })

    $sliderTarget.slick(this.getSlickOptions(epiOptions))
  }
  

  setHeroCarouselHeights() {
    const $items = this.$(this.options.containerItems)
    $items.css('height', '')

    var heights = []
    for (var item in $items) {
      if ($items[item].clientHeight) {
        heights.push($items[item].clientHeight)
      }
    }

    $items.height(heights.reduce((a, b) => (a > b ? a : b), 0))

    // slick dots on mobile and tablet
    if (window.matchMedia('(max-width: 768px)').matches) {
      $('.slick-dots').attr('style', 'top:' + heights[0] + 'px');
      // $('.slick-dots').attr('style', 'top: calc(' + heights[0] - 30 + ')' );
      // $('.slick-dots').attr('style', "top: calc(" + heights[0] + " - 30)");
    }
    
  }
}
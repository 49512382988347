export function triggerElementEvent(
  element: HTMLElement,
  eventName: string,
  eventData?: any
) {
  const bubbles = true
  const cancelable = true

  let event: Event
  if (typeof CustomEvent === 'function') {
    event = new CustomEvent(eventName, {
      bubbles,
      cancelable,
      detail: eventData
    })
  } else {
    event = document.createEvent('Event')
    event.initEvent(eventName, bubbles, cancelable)

    // @ts-ignore
    event.detail = eventData
  }

  element.dispatchEvent(event)
}

import Console from '../utils/Console'
import Bootstrapper from '../setup/Bootstrapper'

export default class BaseModule {
  readonly bootstrapper: Bootstrapper
  readonly moduleName: string

  constructor(bootstrapper: Bootstrapper) {
    this.bootstrapper = bootstrapper
    this.moduleName = this.constructor.name

    // @ts-ignore
    Console.debug(`Instancing new module "${this.moduleName}"`)
  }
}

import BaseDOMModule from '../../../core/modules/BaseDOMModule'
import PDPService from '../../../services/commerce/PDPService'

export default class PDPChangeVariant extends BaseDOMModule {
  static domEvents = {
    'change [data-selector]': 'onVariantChange'
  }

  constructor(...args) {
    super(...args)

    PDPService.on(PDPService.VARIANT_UPDATE, this.onVariantUpdate.bind(this))
  }

  onVariantUpdate(variantSku) {
    this.setActiveVariant(variantSku)
  }

  onVariantChange(event, $target) {
    PDPService.changeVariant($target.val())
  }

  setActiveVariant(variantSku) {
    this.$('[data-selector]').val(variantSku)
    window.history.replaceState({}, '', `?variantCode=${variantSku}`)
  }
}

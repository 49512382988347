import $ from 'jquery'

const noScrollClass = 'no-scroll'
let bodyScrollCount = 0

export const $window = $(window)
export const $body = $('body')
export const $header = $('.header')

export function toggleBodyScroll(shouldScroll) {
  if (shouldScroll === true) {
    bodyScrollCount = Math.max(0, bodyScrollCount - 1)
  } else {
    bodyScrollCount += 1
  }

  $body.toggleClass(noScrollClass, bodyScrollCount > 0)
}

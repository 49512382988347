import $ from 'jquery'
import BaseModule from './BaseModule'
import Console from '../utils/Console'
import noop from '../utils/fn/noop'
import {
  addScrollListener,
  addResizeListener,
  addOnLoadListener
} from '../utils/windowEvents'
import Bootstrapper from '../setup/Bootstrapper'

const splitRegex = /^(\S+)\s*(.*)$/

type EventsDefinitions = {
  [eventAndSelector: string]: string
}

type EventsDefinitionsFactory = () => EventsDefinitions

type FunctionDictionary = {
  [functionName: string]: Function | undefined
}

export default class BaseDOMModule<IModuleOptions = any> extends BaseModule {
  readonly $element: JQuery<HTMLElement>
  readonly options: IModuleOptions

  static windowEvents?: EventsDefinitions
  static domEvents?: EventsDefinitions | EventsDefinitionsFactory

  constructor(
    bootstrapper: Bootstrapper,
    $element: JQuery<HTMLElement>,
    options: IModuleOptions = {} as IModuleOptions
  ) {
    super(bootstrapper)

    this.$element = $element
    this.options = options

    if (!$element) {
      Console.error(
        `BaseDOMModule ("${this.moduleName}") was instantiated without a $DOM-element`
      )
      return
    }

    this.bindDOMEvents()
    this.bindWindowEvents()
  }

  bindWindowEvents() {
    let { windowEvents } = this.constructor as typeof BaseDOMModule
    if (!windowEvents) {
      return
    }

    Object.keys(windowEvents).forEach(eventType => {
      const eventCallbackName = windowEvents![eventType]
      const eventCallback = this.createEventCallback(eventCallbackName)
      switch (eventType) {
        case 'scroll':
          addScrollListener(eventCallback)
          break

        case 'resize':
          addResizeListener(eventCallback)
          break

        case 'onload':
          addOnLoadListener(eventCallback)
          break
      }
    })
  }

  bindDOMEvents() {
    let { domEvents } = this.constructor as typeof BaseDOMModule

    if (typeof domEvents === 'function') {
      domEvents = domEvents()
    }

    if (!domEvents) {
      return
    }

    Object.keys(domEvents).forEach(eventDefinition => {
      let [, eventType, eventSelector] = eventDefinition.match(splitRegex)!

      const eventCallbackName = (domEvents as EventsDefinitions)[
        eventDefinition
      ]
      const eventCallback = this.createEventCallback(
        eventCallbackName,
        eventSelector
      )

      const $root = this.$element

      $root.on(eventType, eventSelector, eventCallback)
    })
  }

  createEventCallback(eventCallbackName: string, eventSelector?: string) {
    const fn = ((this as unknown) as FunctionDictionary)[eventCallbackName]

    if (typeof fn !== 'function') {
      Console.error(
        `Function with name "${eventCallbackName}" not found on module "${this.constructor.name}"`
      )
      return noop
    }

    return (...args: any[]) => {
      const event = args[0] || {}
      const $eventTarget = $(event.target)
      const $selectorTarget = eventSelector
        ? $eventTarget.closest(eventSelector)
        : $eventTarget
      fn.call(this, ...args, $selectorTarget, $eventTarget)
    }
  }

  /**
   * Shorthand helper for this.$element.find
   * @param {string} selector
   * @returns jQuery
   */
  $(selector: string) {
    return this.$element.find(selector)
  }
}

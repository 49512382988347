import BaseDOMModule from '../core/modules/BaseDOMModule'
import { renderDataObjectInElement } from '../core/utils/templateEngine'
import { toggleBodyScroll } from '../services/GlobalDOM'

export default class Dialog extends BaseDOMModule {
  static domEvents = {
    click: 'onDialogClick',
    'click [data-close]': 'hide'
  }

  dialogInnerTarget = '[data-dialog-inner]'

  onDialogClick(event, $dialog, $eventTarget) {
    const isOutsideDialog =
      $eventTarget.closest(this.dialogInnerTarget).length === 0

    if (isOutsideDialog) {
      this.hide()
    }
  }

  render(dialogData) {
    renderDataObjectInElement(this.$element, {
      title: dialogData.title,
      body: { _html: dialogData.body },
      actionText: { _html: dialogData.actionText },
      closeLabel: dialogData.closeLabel
    })
  }

  show(dialogData) {
    this.render(dialogData)
    this.$element.addClass(this.options.activeClass)
    toggleBodyScroll(false)
  }

  hide() {
    this.$element.removeClass(this.options.activeClass)
    toggleBodyScroll(true)
  }
}

import $ from 'jquery'
import 'slick-carousel'
import BaseTemplateModule from '../../../core/modules/BaseTemplateModule'
import PDPService from '../../../services/commerce/PDPService'

export default class PDPImageCarousel extends BaseTemplateModule {
  static domEvents = {
    'beforeChange [data-slider]': 'onSlickChange',
    'click [data-thumbnail-link]': 'onThumbnailClick'
  }

  slickOptions = {
    arrows: false
  }

  constructor(...args) {
    super(...args)

    PDPService.on(PDPService.VARIANT_UPDATE, this.onVariantUpdate.bind(this))

    this.initializeSlickSlider()
  }

  onVariantUpdate(variantSku) {
    if (this.$slickSlider) {
      this.$slickSlider.slick('unslick')
    }

    const images = [
      ...PDPService.getVariantData(variantSku).images,
      ...PDPService.getProductImages()
    ]

    this.renderItems('sliderItem', images, image => ({
      imageSrc: { _attr: { src: image.url, alt: image.content.description } }
    }))

    this.renderItems('thumbnailItem', images, (image, index) => ({
      _attr: { 'data-thumbnail-link': index },
      imageSrc: { _attr: { src: image.url, alt: image.content.description } }
    }))

    this.initializeSlickSlider()
  }

  onSlickChange(event, slick, currentSlideIndex, nextSlideIndex) {
    this.setActiveThumbnail(nextSlideIndex)
  }

  onThumbnailClick(event, $thumbnailLink) {
    const slideIndex = parseInt($thumbnailLink.data('thumbnail-link'), 10)
    this.setActiveSlide(slideIndex)
  }

  initializeSlickSlider() {
    this.$slickSlider = this.$('[data-slider]').slick(this.slickOptions)
    this.setActiveSlide(0)
  }

  setActiveSlide(slideIndex) {
    this.$slickSlider.slick('slickGoTo', slideIndex)
  }

  setActiveThumbnail(activeIndex) {
    const { activeThumbnailClass } = this.options
    const $thumbnails = this.$('[data-thumbnail-link]')
    $thumbnails.removeClass(activeThumbnailClass)
    $($thumbnails.get(activeIndex)).addClass(activeThumbnailClass)
  }
}

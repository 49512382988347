import $ from 'jquery'
import BaseDOMModule from '../../../core/modules/BaseDOMModule'

export default class PDPDescriptionAccordion extends BaseDOMModule {
  static domEvents = {
    'click [data-read-more-link-desktop]': 'goToDescription',
    'click [data-read-more-link-mobile]': 'goToDescriptionMobile'
  }

  $readMore = null
  $readMore = null
  $descriptionTab = null
  $descriptionContent = null
  $tabs__list = null
  $activeTab = null
  $tabs__content = null
  $activeContent = null

  constructor(...args) {
    super(...args)

    this.$readMore = $('.pdp__read-more')
    this.$descriptionTab = $('#productDescriptionTab')
    this.$descriptionContent = $('#productDescriptionContent')
    this.$tabs__list = $('.tabs__tab-link')
    this.$activeTab = $('.tabs__tab-link--is-active')
    this.$tabs__content = $('.tabs__content')
    this.$activeContent = $('.tabs__content--is-active')
    // this.handleAccordion()
  }

  isMobile() {
    return $('.pdp__title--is-desktop').css('display') === 'none'
  }

  removeActiveClass() {
    if (this.$activeTab) {
      this.$tabs__list.removeClass('tabs__tab-link--is-active')
    }
    if (this.$activeContent) {
      this.$tabs__content.removeClass('tabs__content--is-active')
    }

  }

  addActiveClass() {
    this.$descriptionTab.addClass('tabs__tab-link--is-active')
    this.$descriptionContent.addClass('tabs__content--is-active')
  }

  goToDescription() {
    this.removeActiveClass()
    this.addActiveClass()
  }

  goToDescriptionMobile() {
    this.removeActiveClass()
    this.addActiveClass()
    this.$descriptionTab[0].scrollIntoView();
  }

}

import BaseTemplateModule from '../core/modules/BaseTemplateModule'
import CountryRedirectService from '../services/CountryRedirectService'
import { setCookie, hasCookie, DEFAULT_PATH } from '../core/utils/Cookies'
import { toggleBodyScroll } from '../services/GlobalDOM'

export default class CountryRedirect extends BaseTemplateModule {
  static domEvents = {
    'click [data-stay]': 'onStayClick',
    'click [data-visit-country]': 'onVisitCountryClick'
  }

  constructor(...args) {
    super(...args)

    if (this.checkIfShow()) {
      this.showElement(true)
      this.loadCountryOptions()
    }
  }

  checkIfShow() {
    return (
      this.options.isUserOnRoot === 'True' &&
      !hasCookie(this.options.cookieName)
    )
  }

  showElement(show) {
    if (show) {
      this.$element.show()
    } else {
      this.$element.hide()
    }

    toggleBodyScroll(!show)
  }

  loadCountryOptions() {
    const loader = this.$('[data-loader]')
    const actionList = this.$('[data-action-list]')

    CountryRedirectService.getCountrySelection()
      .then(selections => {
        loader.hide()
        actionList.show()
        this.renderSelections(selections)
      })
      .catch(() => {
        this.showElement(false)
      })
  }

  renderSelections(selections) {
    selections.forEach(selection => {
      if (
        selection.languageSelectionItems &&
        selection.languageSelectionItems.length > 0
      ) {
        this.renderTemplate(
          'countryHasLanguages',
          {
            label: `${this.options.goToLabel} ${selection.displayName}`,
            languageList: selection.languageSelectionItems.map(language => ({
              link: {
                ctaLinkText: language.displayName,
                _attr: {
                  href: language.urlTargetVisitorCulture,
                  'data-visit-country': language.targetLocale
                }
              }
            }))
          },
          false
        )
      } else {
        this.renderTemplate(
          'countryNoLanguages',
          {
            link: {
              ctaLinkText: `${this.options.goToLabel} ${selection.displayName}`,
              _attr: {
                href: selection.urlTargetVisitorCulture,
                'data-visit-country': selection.targetLocale
              }
            }
          },
          false
        )
      }
    })
  }

  onVisitCountryClick(event, $element) {
    setCookie(
      this.options.cookieName,
      $element.data('visitCountry'),
      Infinity,
      DEFAULT_PATH
    )
  }

  onStayClick(event, $element) {
    event.preventDefault()
    this.showElement(false)

    setCookie(this.options.cookieName, 'en', Infinity, DEFAULT_PATH)
  }
}

import BaseDOMModule from '../core/modules/BaseDOMModule'
import { animateValue, animateScroll } from '../core/utils/animation'
import { easeOutCubic } from '../core/utils/easings'
import { $window, $header } from '../services/GlobalDOM'

export default class ToggleTilesExpand extends BaseDOMModule {
  static domEvents = {
    'click [data-close]': 'onCloseClick',
    'click [data-toggle]': 'onToggleClick'
  }

  $expandContainer = this.$('[data-expand-container]')
  $expandInner = this.$('[data-expand-inner]')

  animateFromValue = 0
  previousOpenTargetValue = 0
  animationPromise = null

  animationDuration = 500

  isAnimating = () => this.animationPromise !== null

  constructor(...args) {
    super(...args)

    this.finishAnimation = this.finishAnimation.bind(this)
  }

  onCloseClick() {
    this.toggleClick(false)
  }

  onToggleClick() {
    this.toggleClick(this.$element.hasClass(this.options.className) === false)
  }

  toggleClick(shouldOpen) {
    this.prepareAnimateElement(shouldOpen)

    this.$element.toggleClass(this.options.className, shouldOpen === true)

    this.animateElement(shouldOpen)
    this.animateScroll(shouldOpen)
  }

  prepareAnimateElement(shouldOpen) {
    const { animationPromise, $expandContainer } = this
    const isAnimating = this.isAnimating()

    this.animateFromValue =
      shouldOpen === true && isAnimating === false
        ? 0
        : $expandContainer.innerHeight()

    // Remove values and transition
    $expandContainer.removeAttr('style')

    if (animationPromise) {
      animationPromise.cancel()
    }
  }

  animateElement(shouldOpen) {
    const { $expandContainer, $expandInner, animateFromValue } = this

    $expandContainer.css('display', 'block')

    const targetValue = shouldOpen ? $expandContainer.innerHeight() : 0

    if (shouldOpen) {
      this.previousOpenTargetValue = targetValue
    }

    this.animationPromise = animateValue(
      animateFromValue,
      targetValue,
      this.animationDuration,
      stepValue => {
        const innerValue = shouldOpen
          ? -(targetValue - stepValue)
          : -(this.previousOpenTargetValue - stepValue)

        $expandContainer.css('height', stepValue)
        $expandInner.css('transform', `translate3d(0, ${innerValue}px, 0)`)
      },
      easeOutCubic
    )

    this.animationPromise.then(this.finishAnimation, () => {})
  }

  animateScroll(shouldOpen) {
    let headerHeight = $header.height()

    if (!headerHeight) {
      headerHeight = 0
    }

    const scrollTo =
      shouldOpen === true
        ? this.$expandContainer.offset().top - 60
        : this.$element.offset().top - 20

    const windowScroll = $window.scrollTop()
    if (shouldOpen === false && scrollTo > windowScroll) {
      return
    }

    animateScroll(scrollTo - headerHeight, this.animationDuration, easeOutCubic)
  }

  finishAnimation() {
    this.animationPromise = null
    this.$expandContainer.removeAttr('style')
    this.$expandInner.removeAttr('style')
  }
}

import BaseDOMModule from '../core/modules/BaseDOMModule'
import DialogService from '../services/DialogService'

export default class CountrySelectorLink extends BaseDOMModule {
  static domEvents = {
    click: 'onClick'
  }

  onClick(event) {
    event.preventDefault()
    DialogService.openCountrySelectionDialog()
  }
}

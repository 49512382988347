import BaseDOMModule from '../core/modules/BaseDOMModule'

export default class Manual extends BaseDOMModule {
  static domEvents = {
    'change [data-select-manual-language]': 'onValueChanged'
  }

  onValueChanged(event, $element) {
    if ($element.val()) {
      window.location.href = $element.val()
    }
  }
}

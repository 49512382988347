import BaseTemplateModule from '../core/modules/BaseTemplateModule'
import { $window, $header } from '../services/GlobalDOM'
import { easeOutCubic } from '../core/utils/easings'
export default class FormExpand extends BaseTemplateModule {
  static domEvents = () => ({
    'click [data-toggle]': 'onToggleClick'
  })

  $expandContainer = this.$('[data-form-expand-container]')
  animationDuration = 500
  mobileWidthThreshold = 1014

  constructor(...args) {
    super(...args)

    this.$submitButton = $('input[type="submit"]')
    this.$wrapper = $('.form-expand')

    if (this.shouldExpandOnLoad()) {
      this.$element.toggleClass(this.options.activeClass, true)
    }
    if (this.$wrapper.hasClass('bg-color-1')) {
      this.$submitButton.addClass('blue-button')
    }
  }

  shouldExpandOnLoad() {
    return this.options.shouldBeExpand === 'True' && this.isDesktopWidth()
  }

  isDesktopWidth() {
    return $window.width() >= this.mobileWidthThreshold
  }

  onToggleClick() {
    this.toggleExpand(
      this.$element.hasClass(this.options.activeClass) === false
    )
    document.getElementById('expandButton').classList.add('hidden')
    $('.form-expand-lower').toggleClass('show')
  }

  toggleExpand(shouldShow) {
    this.$element.toggleClass(this.options.activeClass, shouldShow === true)
  }
}

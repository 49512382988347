import $ from 'jquery'
import BaseTemplateModule from '../../../core/modules/BaseTemplateModule'
import CheckoutService from '../../../services/commerce/CheckoutService'
import ClientData from '../../../services/ClientData'

export default class CheckoutShippingMethods extends BaseTemplateModule {
  constructor(...args) {
    super(...args)

    CheckoutService.on(
      CheckoutService.CHECKOUT_DETAILS_UPDATED,
      this.onCheckoutDetailsUpdate.bind(this)
    )
  }

  onCheckoutDetailsUpdate(checkoutDetails) {
    const selectedId = this.$('[data-input-shipping-method]:checked').val()
    const $noShippingMethodText = this.$('[data-no-shipping-methods]')

    const { shippingMethods } = checkoutDetails
    this.renderItems('shippingMethod', shippingMethods, shippingMethod => ({
      inputShippingMethod: { _attr: { value: shippingMethod.id } },
      name: shippingMethod.displayName + ' ' + shippingMethod.description,
      description: shippingMethod.description,
      price:
        shippingMethod.priceAmount <= 0
          ? ClientData.getTranslation('freeShipping')
          : shippingMethod.priceIncVAT,
      image: {
        _attr: {
          src: shippingMethod.imageUrl,
          style: shippingMethod.imageUrl == null ? 'display:none;' : ''
        }
      }
    }))

    let $selectedMethod = this.$(`[value="${selectedId}"]`)

    if (shippingMethods.length === 0) {
      $noShippingMethodText.addClass(
        'checkout-form-shipping__no-options-label--show'
      )
    } else {
      $noShippingMethodText.removeClass(
        'checkout-form-shipping__no-options-label--show'
      )
    }

    if (!$selectedMethod.length) {
      $selectedMethod = $(this.$('[data-input-shipping-method]').get(0))
    }
    $selectedMethod.prop('checked', true)
  }
}

import $ from 'jquery'

const $window = $(window)

const scrollEventHandlers = []
const resizeEventHandlers = []
const onLoadEventHandlers = []

const createListener = (eventType, handlers, valueCallback) => {
  let rafId = null

  const triggerEvent = event => {
    const eventValue = valueCallback()
    handlers.forEach(handler => handler(event, eventValue))
  }

  $window.on(eventType, event => {
    cancelAnimationFrame(rafId)
    rafId = requestAnimationFrame(() => triggerEvent(event))
  })

  requestAnimationFrame(() =>
    triggerEvent({ target: window, event: eventType })
  )
}

export function addScrollListener(handler) {
  if (scrollEventHandlers.length === 0) {
    createListener('scroll', scrollEventHandlers, () => $window.scrollTop())
  }

  scrollEventHandlers.push(handler)
}

export function addResizeListener(handler) {
  if (resizeEventHandlers.length === 0) {
    createListener('resize', resizeEventHandlers, () =>
      Math.max($window.width(), window.innerWidth)
    )
  }
  resizeEventHandlers.push(handler)
}

export function addOnLoadListener(handler) {
  if (onLoadEventHandlers.length == 0) {
    createListener('load', onLoadEventHandlers, () => {})
  }

  onLoadEventHandlers.push(handler)
}

const { NordLock } = window

class ClientData {
  get(dataPath, defaultValue = null) {
    return dataPath
      .split('.')
      .reduce((obj, key) => (obj[key] ? obj[key] : defaultValue), NordLock)
  }

  getPageModelData(pageModelName, defaultValue) {
    return this.get(`pageModels.${pageModelName}`, defaultValue)
  }

  getTranslation(prop, defaultValue) {
    return this.get(`commonTranslations.${prop}`, defaultValue)
  }

  getCurrentLocale() {
    return NordLock.currentLocale
  }
}

export default new ClientData()

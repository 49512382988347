import $ from 'jquery'
import BaseDOMModule from '../../../core/modules/BaseDOMModule'
import CheckoutService from '../../../services/commerce/CheckoutService'
import CartService from '../../../services/commerce/CartService'
import Console from '../../../core/utils/Console'

export default class CheckoutVAT extends BaseDOMModule {
  static domEvents = {
    submit: 'onFormSubmit'
  }

  constructor(...args) {
    super(...args)

    CartService.on(CartService.CART_UPDATED, this.onCartUpdated.bind(this))
  }

  onCartUpdated(cart) {
    if (cart.lineItems.length <= 0) {
      this.$('button[type="submit"]').each((i, element) => {
        $(element).attr('disabled', true)
      })

      this.$('input').each((i, element) => {
        $(element).attr('disabled', true)
      })
    }
  }

  onFormSubmit(event) {
    event.preventDefault()

    const $fieldset = this.$('[data-fieldset-vat]')
    const $errorOutput = this.$('[data-error-output]')
    const $vatNumber = this.$('[data-input-vat-number]')

    const vatNumber = $vatNumber.val()

    $fieldset.attr('disabled', true)
    $errorOutput.text('')

    CheckoutService.setVatNumber(vatNumber)
      .catch(error => {
        Console.error(error)
        $errorOutput.text(error.responseJSON.message)
      })
      .finally(() => $fieldset.removeAttr('disabled'))
  }
}

import BaseTemplateModule from '../core/modules/BaseTemplateModule'

export default class RedirectDropdown extends BaseTemplateModule {
  static domEvents = {
    'change [data-selector]': 'onChange'
  }

  constructor(...args) {
    super(...args)

    this.$('[data-selector]').val(this.options.selectedVal)
  }

  onChange() {
    const newUrl =
      this.$('[data-selector]')
        .find(':selected')
        .data('redirectUrl') || this.$('[data-selector]').val()

    window.location.href = newUrl
  }
}

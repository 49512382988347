import BaseTemplateModule from '../../core/modules/BaseTemplateModule'
import CartService from '../../services/commerce/CartService'

export default class HeaderCartDetails extends BaseTemplateModule {
  $cartHeaderItemTemplate = null

  constructor(...args) {
    super(...args)

    CartService.on(CartService.CART_UPDATED, this.onCartUpdate.bind(this))
  }

  onCartUpdate(cart) {
    const { lineItems } = cart

    this.renderItems('cartHeaderItem', lineItems, item => ({
      name: item.productName,
      image: { _attr: { src: item.imageUrl } },
      qty: item.quantity,
      url: { _attr: { href: item.url } }
    }))

    if (lineItems.length > 0) {
      this.$element.removeClass('no-items')
    }
  }
}

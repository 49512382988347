import BaseDOMModule from '../core/modules/BaseDOMModule'

export default class HeaderScroll extends BaseDOMModule {
  static windowEvents = {
    scroll: 'onWindowScroll'
  }

  onWindowScroll(event, scrollTop) {
    const { solidClassName } = this.options
    const isScrolled = scrollTop > 0
    this.$element.toggleClass(solidClassName, isScrolled)
  }
}

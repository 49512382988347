import { currentQueryString, updateQueryParams } from './location'

export const updateQuery = (queryKey, queryValue) => {
  const queryObject = getQueryObject()
  queryObject[queryKey] = queryValue
  updateQueryParams(queryObject)
}

export const getQueryObject = () =>
  currentQueryString()
    .split('&')
    .reduce((queryObject, queryParam) => {
      const [key, value = true] = queryParam.split('=')
      return { ...queryObject, [key]: value }
    }, Object.create(null))

export const getQuery = queryKey => {
  const queryObject = getQueryObject()
  return queryKey in queryObject ? queryObject[queryKey] : null
}

export const buildQueryString = queryObject =>
  Object.keys(queryObject)
    .map(key => `${key}=${queryObject[key]}`)
    .join('&')

export const createQueryObject = queryString =>
  queryString.split('&').reduce((queryObject, queryParam) => {
    const [key, value = true] = queryParam.split('=')
    return { ...queryObject, [key]: value }
  }, Object.create(null))

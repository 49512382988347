import BaseDOMModule from '../core/modules/BaseDOMModule'

export default class Hotspots extends BaseDOMModule {
  static windowEvents = {
    onload: 'initializeHotspots'
  }

  openHotspotIndex: number | null = null
  hotspotsHaveAlreadyLoaded = false // This is a workaround becuase there is some script re-triggering the onload, this prevent the event listeners to be initialized twice.

  initializeHotspots() {
    if (this.hotspotsHaveAlreadyLoaded) return

    const hotspotsContainer = document.querySelector('.hotspots')
    const hotspots =
      hotspotsContainer?.querySelector('.ewrapper')?.children || []

    // Unfortunately this is necessary for IE11
    for (let index = 0; index < hotspots.length; index++) {
      const hotspotElement: HTMLDivElement | null = document.querySelector(
        `[data-hotspot='${index}']`
      )
      const hotspotElementPosition = hotspotElement
        ?.getAttribute('data-hotspot-position')
        ?.split('|') || ['0', '0']

      const [hotspotX, hotspotY] = hotspotElementPosition

      if (hotspotElement) {
        hotspotElement.style.left = `${Number(hotspotX) * 100}%`
        hotspotElement.style.top = `${Number(hotspotY) * 100}%`
      }

      const hotspotTrigger: HTMLButtonElement | null = document.querySelector(
        `[data-hotspot-button='${index}']`
      )

      if (hotspotElement) {
        const hotspotTriggerIndicator = hotspotElement?.querySelector(
          '.hotspots__item-button-indicator'
        )

        const randomAnimationDuration = 1 + Math.random() // Random values between 1 and 2
        hotspotTriggerIndicator?.setAttribute(
          'style',
          `animation: blink ${randomAnimationDuration}s infinite alternate-reverse ease-in-out`
        )
      }

      hotspotTrigger?.addEventListener('click', () => {
        this.stopHotspotsBlinking(hotspots)

        if (this.openHotspotIndex !== index) {
          this.openHotspotIndex = index
          this.updateHotspots(hotspots)
        } else {
          this.openHotspotIndex = null
          this.updateHotspots(hotspots)
        }
      })

      const hotspotContentCloseButton = document.querySelector(
        `[data-hotspot-content-close-button='${index}']`
      )
      hotspotContentCloseButton?.addEventListener('click', () => {
        this.openHotspotIndex = null
        this.updateHotspots(hotspots)
        hotspotTrigger?.focus()
      })

      this.hotspotsHaveAlreadyLoaded = true
    }
  }

  updateHotspots(hotspots: HTMLCollection | never[]) {
    if (!hotspots) return

    for (let index = 0; index < hotspots.length; index++) {
      const hotspotsContainer = document.querySelector('.hotspots')

      const hotspotElement = document.querySelector(`[data-hotspot='${index}']`)

      const hotspotButtonIcon = document.querySelector(
        `[data-hotspot-button-icon='${index}']`
      )
      const hotspotContentIndicator = document.querySelector(
        `[data-hotspot-content-indicator='${index}']`
      )
      const hotspotContent: HTMLSpanElement | null = document.querySelector(
        `[data-hotspot-content='${index}']`
      )
      const htmlContentElement = hotspotContent?.querySelector(
        '.hotspots__item-content-html'
      )

      const hotspotElementPosition = hotspotElement
        ?.getAttribute('data-hotspot-position')
        ?.split('|') || ['0', '0']

      const [hotspotX, hotspotY] = hotspotElementPosition

      const section = this.getPlaneSection(Number(hotspotX), Number(hotspotY))

      if (index === this.openHotspotIndex) {
        hotspotButtonIcon?.setAttribute(
          'class',
          'hotspots__item-button-icon hotspots__item-button-icon--is-active'
        )

        hotspotContentIndicator?.setAttribute(
          'class',
          `hotspots__item-content-indicator-${section}`
        )
        hotspotContent?.setAttribute(
          'class',
          `hotspots__item-content hotspots__item-content--is-visible hotspots__item-content-section-${section}`
        )

        if (hotspotsContainer && hotspotContent) {
          const isMobile = hotspotsContainer.clientWidth < 800

          const hotspotDistanceToTop =
            hotspotsContainer?.clientHeight * Number(hotspotY)
          const hotspotDistanceToBottom =
            hotspotsContainer?.clientHeight - hotspotDistanceToTop

          const hotspotContentMaxHeight = Math.max(
            hotspotDistanceToBottom,
            hotspotDistanceToTop
          )

          if (!isMobile) {
            hotspotContent.style.maxHeight = `${hotspotContentMaxHeight - 50}px`
          }
        }

        if (hotspotContent && htmlContentElement) {
          hotspotContent.scrollTop = 0
          htmlContentElement.scrollTop = 0
        }
      } else {
        hotspotButtonIcon?.setAttribute('class', 'hotspots__item-button-icon')
        hotspotContent?.setAttribute(
          'class',
          `hotspots__item-content hotspots__item-content-section-${section}`
        )
      }
    }
  }

  stopHotspotsBlinking(hotspots: HTMLCollection | never[]) {
    for (let index = 0; index < hotspots.length; index++) {
      const hotspotElement: HTMLDivElement | null = document.querySelector(
        `[data-hotspot='${index}']`
      )

      const hotspotTriggerIndicator = hotspotElement?.querySelector(
        '.hotspots__item-button-indicator'
      )

      if (hotspotTriggerIndicator) {
        hotspotElement?.removeChild(hotspotTriggerIndicator)
      }
    }
  }

  getPlaneSection(x: number, y: number) {
    const horizontalBreakpoint = 0.5
    const [verticalBreakpoint1, verticalBreakpoint2] = [0.333, 0.666]
    if (x < horizontalBreakpoint && y < verticalBreakpoint1) return 1
    if (x > horizontalBreakpoint && y < verticalBreakpoint1) return 2
    if (
      x < horizontalBreakpoint &&
      y > verticalBreakpoint1 &&
      y < verticalBreakpoint2
    )
      return 3
    if (
      x > horizontalBreakpoint &&
      y > verticalBreakpoint1 &&
      y < verticalBreakpoint2
    )
      return 4
    if (x < horizontalBreakpoint && y > verticalBreakpoint2) return 5
    if (x > horizontalBreakpoint && y > verticalBreakpoint2) return 6
  }
}

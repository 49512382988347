import BaseService from '../core/services/BaseService'
import Api from './Api'

class ClickDimensionService extends BaseService {
  async postForm(serializedFormData) {
    await Api.post('/clickdimension/form', serializedFormData, {
      processData: false
    })
  }
}

export default new ClickDimensionService()

import BaseService from '../core/services/BaseService'

class ContentToggleService extends BaseService {
  CONTENT_EXPAND = 'contentToggle.expand'

  toggleExpand(moduleName, expand) {
    this.emit(this.CONTENT_EXPAND, { moduleName, expand })
  }
}

export default new ContentToggleService()

import BaseService from '../../core/services/BaseService'
import Api from '../Api'
import ClientData from '../ClientData'

// [Obsolete]
class MarketService extends BaseService {
  MARKET_UPDATE = 'market.update'

  headerMarketClientDataKey = 'headerMarket'

  async ChangeMarketInfo(countryCode, currencyCode, market, callback) {
    const response = await Api.post('/nlmarket/set', {
      countryCode,
      currencyCode,
      market
    })

    const override = callback == null || !callback()

    if (override) {
      this.emit(this.MARKET_UPDATE, {
        ...response,
        countryCode,
        currencyCode,
        market
      })
    }
  }

  getCountryDeliveryText(countryCode) {
    let deliveryInfoTextKey = 'deliveryInfoRow' //default value
    const deliveryInfoItems = ClientData.getPageModelData(
      `${this.headerMarketClientDataKey}.deliveryInfoItems`,
      []
    )
    const deliveryInfoTexts = ClientData.getPageModelData(
      `${this.headerMarketClientDataKey}.deliveryInfoTexts`,
      {}
    )

    const item = deliveryInfoItems.find(item => item.country === countryCode)
    if (item) {
      deliveryInfoTextKey = Object.keys(deliveryInfoTexts).find(
        key => key.toLowerCase() === item.deliveryInfoKey.toLowerCase()
      )
    }

    return deliveryInfoTexts[deliveryInfoTextKey]
  }
}

export default new MarketService()

import BaseService from '../../core/services/BaseService'
import ClientData from '../ClientData'
import { onLocationUpdate } from '../../core/utils/location'
import { updateQuery, getQuery } from '../../core/utils/query'

class PDPService extends BaseService {
  VARIANT_UPDATE = 'variant.update'

  clientDataKey = 'productDetailsPage'
  variantQueryKey = 'variantCode'

  constructor(...args) {
    super(...args)

    onLocationUpdate(this.onLocationUpdate.bind(this))
  }

  onLocationUpdate() {
    const querySku = getQuery(this.variantQueryKey)
    this.emit(this.VARIANT_UPDATE, querySku)
  }

  changeVariant(sku) {
    updateQuery(this.variantQueryKey, sku)
    this.emit(this.VARIANT_UPDATE, sku)
  }

  getProductImages() {
    return ClientData.getPageModelData(`${this.clientDataKey}.productImages`)
  }

  getVariantData(variantSku) {
    const allVariants = ClientData.getPageModelData(
      `${this.clientDataKey}.productVariants`,
      []
    )
    return allVariants.find(variant => variant.variantSku === variantSku)
  }
}

export default new PDPService()

import BaseDOMModule from '../core/modules/BaseDOMModule'
import ContentToggleService from '../services/ContentToggleService'

export default class ContentToggleContainer extends BaseDOMModule {
  static domEvents = {
    'click [data-toggle-expand]': 'onToggleExpandClick',
    'click [data-click-overlay]': 'onClickOverlayClick'
  }

  onToggleExpandClick() {
    this.toggleExpand()
  }

  onClickOverlayClick() {
    this.toggleExpand(false)
  }

  toggleExpand(forceState = undefined) {
    this.$element.toggleClass(this.options.expandClass, forceState)

    const childModule = this.$element.find('[data-module]')
    ContentToggleService.toggleExpand(childModule, forceState === false)
  }
}

import $ from 'jquery'

const { IntersectionObserver } = window

export function initializeLazyImages() {
  const $lazyImages = $('img[data-src]')

  // Just load the images if browser doesn't support IntersectionObserver (Assuming that there is no browser that supports native lazy loading, but doesn't support IntersectionObserver). 
  if (!IntersectionObserver) {
    $lazyImages.each((_, imageElement) => loadImage(imageElement))
    return
  }

  // Create observer
  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio <= 0) {
          return
        }

        const imageElement = entry.target
        loadImage(imageElement)
        observer.unobserve(imageElement)
      })
    },
    { rootMargin: '150px' }
  )

  $lazyImages.each((_,imageElement) => {
    if (imageElement.loading === 'lazy' && 'loading' in HTMLImageElement.prototype) {
      // Load image (i.e. let the browser decide when to load it) if using native lazy loading (and the browser supports it).
      loadImage(imageElement);
    } else {
      // Let the image be observed
      observer.observe(imageElement);
    }
  })
}

export function loadImage(imageElement) {
  const $imageElement = $(imageElement)

  const $parent = $imageElement.parent()
  if ($parent.is('picture')) {
    const $sourceElements = $parent.find('source')
    $sourceElements.each((_, sourceElement) => {
      sourceElement.srcset = $(sourceElement).data('srcset')
    })
  }

  imageElement.src = $imageElement.data('src')
}

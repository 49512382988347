import { EventEmitter } from 'events'

export default class BaseService extends EventEmitter {
  constructor(...args) {
    super(...args)

    if (process.env.NODE_ENV === 'development') {
      window.nordLockServices = window.nordLockServices || {}
      window.nordLockServices[this.constructor.name] = this
    }
  }
}

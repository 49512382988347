const { location } = window

let locationStateCallbacks = []

const triggerLocationUpdate = (window.onpopstate = ({ state }) => {
  locationStateCallbacks.forEach(handler => handler(state))
})

export const onLocationUpdate = callback =>
  locationStateCallbacks.push(callback)

export const currentPathname = () => location.pathname

export const currentQueryString = () => location.search.substr(1)

export const currentHash = () => location.hash.substr(1)

export const buildLocationString = (
  pathname = currentPathname(),
  search = currentQueryString(),
  hash = currentHash()
) => {
  let locationString = pathname
  if (search) {
    locationString += `?${search}`
  }
  if (hash) {
    locationString += `#${hash}`
  }
  return locationString
}

export const updateLocation = (newLocation, state = {}) => {
  history.pushState(state, '', newLocation)
  triggerLocationUpdate({ state })
}

export const updateQueryParams = queryObject =>
  updateLocation(
    buildLocationString(
      undefined,
      Object.keys(queryObject)
        .map(key => `${key}=${queryObject[key]}`)
        .join('&')
    )
  )

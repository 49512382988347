import $ from 'jquery'
import BaseDOMModule from '../core/modules/BaseDOMModule'
import CustomDomEventNames from 'Core/constants/CustomDomEventNames'
import { setCookie, getCookie } from '../core/utils/Cookies'

interface FloatingContactFormOptions {
  activeClass: string
  removeAnimationClass: string
  popUpActiveClass: string
}

export default class FloatingContactForm extends BaseDOMModule<
  FloatingContactFormOptions
> {
  static domEvents = () => ({
    'click [data-floater]': 'onFloaterClick',
    'click [data-pop-up-close]': 'onPopupClose',
    [`${CustomDomEventNames.OnFormSuccess} form`]: 'onFormSuccess'
  })

  static windowEvents = {
    resize: 'onWindowResize',
    scroll: 'onScroll',
    onload: 'initializePopUpMessage'
  }

  targetStickyScrollTop = Infinity
  isRemoved = false
  popUpIsActived = false

  cookieKey = 'contact-pop-up'

  initializePopUpMessage() {
    const $popUp = this.$element.find('[data-pop-up]')
    if ($popUp.length > 0) {
      const timeOnPage = 10000

      this.setPopUpHeightTop()
      setTimeout(this.popupShow.bind(this), timeOnPage)
    }
  }

  setPopUpHeightTop() {
    const $popUp = this.$element.find('[data-pop-up]')
    const margin = 60
    $popUp.css('top', -$popUp.height()! - margin)
  }

  onPopupClose() {
    setCookie(this.cookieKey, 'true')
    this.popupClose()
  }

  popupShow() {
    var cookie = getCookie(this.cookieKey)

    if (!this.popUpIsActived && cookie !== 'true') {
      const $popUp = this.$element.find('[data-pop-up]')
      $popUp.addClass(this.options.popUpActiveClass)

      this.popUpIsActived = true
    }
  }

  popupClose() {
    const $popUp = this.$element.find('[data-pop-up]')
    if ($popUp) {
      $popUp.removeClass(this.options.popUpActiveClass)
      $popUp.one('transitionend -webkit-transitionend', () => {
        $popUp.remove()
      })
    }
  }

  onFloaterClick() {
    this.$element.toggleClass(this.options.activeClass)
    this.popupClose()
  }

  onWindowResize() {
    if (this.isRemoved) {
      return
    }

    if (this.popUpIsActived) {
      this.setPopUpHeightTop()
    }

    if ($('body').height() === $(window).height()) {
      this.targetStickyScrollTop = 0
    } else {
      this.targetStickyScrollTop = document.documentElement.scrollHeight * 0.1
    }
  }

  onScroll() {
    if (!this.popUpIsActived) {
      var scrollTop = $(window).scrollTop()
      if (scrollTop! > 1000) {
        this.popupShow()
      }
    }
  }

  onFormSuccess() {
    const successMessageDurationMs = 3000
    setTimeout(this.removeAfterFormSubmit.bind(this), successMessageDurationMs)
  }

  removeAfterFormSubmit() {
    this.$element.addClass(this.options.removeAnimationClass)
    this.$element.one('transitionend -webkit-transitionend', () => {
      this.isRemoved = true
      this.$element.remove()
    })
  }
}

import BaseDOMModule from '../core/modules/BaseDOMModule'
import { getNumberOfPages } from '../utils/manualCalculations'

export default class PageNumber extends BaseDOMModule {
  constructor(...args) {
    super(...args)
    this.applyPageNumber()
  }

  applyPageNumber() {
    const pages = getNumberOfPages();
    const parent = document.getElementsByClassName('page-number')[0];

    for (var i = 1; i <= pages; i++) {
      this.createPageNumberElement(parent, i, pages);
    }
  }

  createPageNumberElement(parent, page, totalPages) {
    var pageNumberElement = document.createElement('div');
    pageNumberElement.classList.add('page-number--item');
    pageNumberElement.style.top = `calc(${page * 100}vh - 20px)`
    pageNumberElement.innerHTML = `<span>${page}/${totalPages}</span>`
    parent.appendChild(pageNumberElement);
  }
}



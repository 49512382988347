import BaseTemplateModule from '../../core/modules/BaseTemplateModule'
import WasherSelectorService from '../../services/commerce/WasherSelectorService'
import capitalize from '../../core/utils/fn/capitalize'

export default class WasherSelectorQuick extends BaseTemplateModule {
  static domEvents = {
    'change [data-select-size]': 'onSizeChange',
    'change [data-select-material]': 'onMaterialChange',
    'change [data-select-outer-diameter]': 'onOuterDiameterChange'
  }

  constructor(...args) {
    super(...args)

    const { size, outerDiameter, material } = this.getSelectedValues()
    if (size !== null && outerDiameter === null && material === null) {
      this.resetDropdowns()
    }

    this.updateMaterialsDropdown()
  }

  onSizeChange() {
    this.updateMaterialsDropdown()
  }

  onMaterialChange() {
    this.updateOuterDiametersDropdown()
  }

  onOuterDiameterChange() {
    this.updateProductLink()
  }

  updateMaterialsDropdown() {
    const { size, unitSystem, material } = this.getSelectedValues()
    const materials = WasherSelectorService.getAvailableMaterials(
      size,
      unitSystem
    ).map(x => x.value)


    const sortedMaterials =
      material === null
        ? materials
        : [material, ...materials.filter(x => x !== material)]

    this.renderItems('materialOption', sortedMaterials, item => ({
      _text: capitalize(item),
      _attr: { value: item }
    }))

    this.updateOuterDiametersDropdown()
  }

  updateOuterDiametersDropdown() {
    const {
      size,
      material,
      unitSystem,
      outerDiameter
    } = this.getSelectedValues()
    const outerDiamaters = WasherSelectorService.getAvailableClearanceHoles(
      size,
      unitSystem,
      material
    )

    const sortedOuterDiameter =
      outerDiameter === null
        ? outerDiamaters
        : [
            outerDiamaters.find(x => x.value === outerDiameter),
            ...outerDiamaters.filter(x => x.value !== outerDiameter)
          ]

    this.renderItems('outerDiameterOption', sortedOuterDiameter, item => ({
      _text: capitalize(item.text),
      _attr: { value: item.value }
    }))

    this.updateProductLink()
  }

  updateProductLink() {
    const {
      size,
      material,
      outerDiameter,
      unitSystem
    } = this.getSelectedValues()

    const washer = WasherSelectorService.getWasher(
      size,
      unitSystem,
      material,
      outerDiameter
    )

    const $washerLink = this.$('[data-washer-link]')
    $washerLink.attr('href', washer ? washer.url : '#')
    $washerLink.toggleClass(this.options.disabledLinkClass, !washer)
  }

  getSelectedValues() {
    return {
      size: this.$('[data-select-size]').val(),
      material: this.$('[data-select-material]').val(),
      outerDiameter: this.$('[data-select-outer-diameter]').val(),
      unitSystem: this.$('[data-select-size] :selected')
        .parent()
        .data('optgroup-unit-system')
    }
  }

  resetDropdowns() {
    this.$('[data-select-size]').prop('selectedIndex', 0)
    this.$('[data-select-material]').prop('selectedIndex', 0)
    this.$('[data-select-outer-diameter]').prop('selectedIndex', 0)
  }
}

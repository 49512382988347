import Search from './Search'

export default class SearchPage extends Search {
  static domEvents = () => ({
    ...Search.domEvents,
    'focus [data-search-input]': 'onInputFocus',
    'keyup [data-search-input]': 'onInputKeyUp',
    'keydown [data-search-input]': 'onInputKeyDown',
    'click [data-click-overlay]': 'onClickOverlayClick',
    'click [data-suggestion-item]': 'onSuggestionClick',
    'submit [data-search-form]': 'onSearchFormSubmit'
  })

  keystrokeTimeout = null
  localStorageKey = 'nl_searches'
  $suggestionsList = this.$('[data-suggestions-list]')

  constructor(...args) {
    super(...args)
    super.onSearchInput()
  }

  onInputKeyUp() {
    clearTimeout(this.keystrokeTimeout)
    this.keystrokeTimeout = setTimeout(() => {
      this.saveUserQuery(this.$searchInput.val())
    }, 1500)
  }

  onInputKeyDown() {
    this.toggle$ElementClass(this.options.showSuggestionsClass, false)
  }

  onInputFocus() {
    this.renderSuggestions()
    this.toggle$ElementClass(this.options.showSuggestionsClass, true)
  }

  onClickOverlayClick() {
    this.toggle$ElementClass(this.options.showSuggestionsClass, false)
  }

  onSuggestionClick(event, $target) {
    event.preventDefault()
    this.$searchInput.val($target.text())
    this.onSearchInput()
    this.toggle$ElementClass(this.options.showSuggestionsClass, false)
  }

  onSearchFormSubmit(event) {
    event.preventDefault()
    this.saveUserQuery(this.$searchInput.val())
    window.location.href = this.getCurrentQueryPathString()
  }

  /* @override */ onSearchInput() {
    super.onSearchInput()

    const queryPath = this.getCurrentQueryPathString()
    window.history.replaceState({}, '', queryPath)
  }

  getCurrentQueryPathString() {
    const queryValue = this.$searchInput.val()
    const brandIds = super.getBrandFilterIds()
    const catIds = super.getCategoryFilterIds()
    const params = new URLSearchParams(window.location.search)

    let querySection = ''
    if (params.keys.length < 1) {
      querySection = '?'
    }

    params.set('query', queryValue)
    params.set('brandIds', brandIds.join())
    params.set('catIds', catIds.join())

    return `${window.location.pathname}${querySection}${decodeURIComponent(
      params.toString()
    )}`
  }

  saveUserQuery(query) {
    if (!window.localStorage) {
      return
    }

    const storedState = localStorage.getItem(this.localStorageKey)
    let items = storedState ? JSON.parse(storedState) : []

    if (items.findIndex(item => item.query === query) > -1) {
      return
    }

    items.push({ query, date: Date.now() })
    items.sort((a, b) => b.date - a.date).splice(10)
    localStorage.setItem(this.localStorageKey, JSON.stringify(items))
  }

  getStoredUserQueries() {
    const storedState = localStorage.getItem(this.localStorageKey)
    const items = storedState ? JSON.parse(storedState) : []

    return items.sort((a, b) => b.date - a.date).slice(0, 5)
  }

  renderSuggestions() {
    const suggestions = this.getStoredUserQueries()

    this.renderItems('searchSuggestionItem', suggestions, item => ({
      suggestion: {
        _text: item.query,
        _attr: { value: item.query }
      }
    }))
  }

  /* @override */ renderResult(resultItems) {
    this.$element.addClass(this.options.hasResultsClass)

    this.renderItems('searchResultItem', resultItems, item => ({
      link: {
        _attr: { href: item.url, target: item.isFile ? '_blank' : '_self' }
      },
      title: item.title,
      text: item.text,
      img: {
        _attr: { src: item.imageUrl + '?width=300' }
      },
      imgContainer: {
        _remove: !item.imageUrl
      }
    }))
  }

  /* @override */ renderTotalCount(totalCount) {
    this.$('[data-item-count]').text(totalCount)
  }
}

import deCamelCase from './fn/deCamelCase'

export function renderDataObjectInElement($element, dataObject) {
  if (!dataObject) {
    return
  }

  Object.keys(dataObject).forEach(dataName => {
    const dataValue = dataObject[dataName]
    renderDataValueInElement($element, dataName, dataValue)
  })
}

export function renderDataValueInElement($element, dataName, dataValue) {
  // _attr  (value={Object})
  // Loop through value and set attributes on $elem
  if (dataName === '_attr') {
    Object.keys(dataValue).forEach(attributeName => {
      const attributeValue = dataValue[attributeName]
      if (attributeName === 'className') {
        $element.addClass(attributeValue)
      } else {
        $element.attr(attributeName, attributeValue)
      }
    })
  } else if (dataName === '_text') {
    // _text
    // Set $elems text to value
    $element.text(dataValue)
  } else if (dataName === '_html') {
    // _html
    // Set $elems html to value
    $element.html(dataValue)
  } else if (dataName === '_remove' && dataValue === true) {
    // _remove
    // If name is _remove and value is true we remove the $elem
    $element.remove()
  } else if (Array.isArray(dataValue)) {
    const childElementSelector = `[data-${deCamelCase(dataName)}]`
    const $childElement = $element.find(childElementSelector)
    const $parent = $childElement.parent()
    $parent.empty()

    dataValue.forEach(item => {
      const $template = $childElement.clone()
      renderDataObjectInElement($template, item)
      $parent.append($template)
    })
  } else {
    // Find element with dataName and render into it
    const childElementSelector = `[data-${deCamelCase(dataName)}]`
    const $childElement = $element.find(childElementSelector)

    if (typeof dataValue === 'object') {
      renderDataObjectInElement($childElement, dataValue)
    } else {
      // Default
      // Find child in $elem and set text on child to value
      $childElement.text(dataValue)
    }
  }
}

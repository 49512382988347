import $ from 'jquery'
import { isIE } from 'Core/utils/browser'
import { $body } from '../services/GlobalDOM'

const svgSpritesheetHrefRegex = /^(\/[^#]+)(#[A-z0-9_-]+)$/

//
// See https://jira.kruso.dk/browse/NL-1615 for reasoning
//
export function checkAndApplySvgSpritesheetFixForIE() {
  if (!isIE) {
    return
  }

  const spritesheetUrls: string[] = []

  const $allSvgUseElements = $<SVGUseElement>('svg use')

  $allSvgUseElements.each((index, useElement) => {
    const $useElement = $(useElement)

    const href = $useElement.attr('href') || $useElement.attr('xlink:href')
    if (!href) {
      return
    }

    const hrefMatch = href.match(svgSpritesheetHrefRegex)
    if (!hrefMatch) {
      return
    }

    const [, spritesheetUrl, svgId] = hrefMatch

    if (!spritesheetUrls.includes(spritesheetUrl)) {
      spritesheetUrls.push(spritesheetUrl)
    }

    $useElement.attr('href', svgId)
  })

  spritesheetUrls.forEach(spritesheetUrl => {
    const $request = $.ajax({
      method: 'GET',
      url: spritesheetUrl,
      dataType: 'text',
      headers: {
        accept: 'image/svg+xml'
      }
    })

    $request.then(spritesheetContents => {
      $body.prepend(spritesheetContents)
    })
  })
}

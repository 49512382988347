import $ from 'jquery'
import CheckoutService from '../../../services/commerce/CheckoutService'
import CartService from '../../../services/commerce/CartService'
import Console from '../../../core/utils/Console'
import BaseTemplateModule from '../../../core/modules/BaseTemplateModule'

export default class CheckoutCouponCode extends BaseTemplateModule {
  static domEvents = {
    submit: 'onFormSubmit'
  }

  constructor(...args) {
    super(...args)

    CartService.on(CartService.CART_UPDATED, this.onCartUpdated.bind(this))
    CheckoutService.on(
      CheckoutService.CHECKOUT_DETAILS_UPDATED,
      this.onCheckoutDetailsUpdate.bind(this)
    )
  }

  onCartUpdated(cart) {
    if (cart.lineItems.length <= 0) {
      this.$('button[type="submit"]').each((i, element) => {
        $(element).attr('disabled', true)
      })

      this.$('input').each((i, element) => {
        $(element).attr('disabled', true)
      })
    }
  }

  onCheckoutDetailsUpdate(checkoutDetails) {
    const discountNames = [
      ...new Set(
        checkoutDetails.cartDetails.discountDetails
          .filter(discount => discount.name !== null)
          .map(x => x.name)
      )
    ]

    this.$('.cart-coupons__applied').toggle(discountNames.length > 0)

    this.renderItems('appliedCoupon', discountNames, item => ({
      _text: `"${item}"`
    }))
  }

  onFormSubmit(event) {
    event.preventDefault()

    const $fieldset = this.$element
    const $errorOutput = this.$('[data-error-output]')
    const $couponCode = this.$('[data-input-coupon-code]')

    const couponCode = $couponCode.val()

    $fieldset.attr('disabled', true)
    $errorOutput.text('')

    CheckoutService.addCouponCode(couponCode)
      .then(() => $couponCode.val(''))
      .catch(error => {
        Console.error(error)
        $errorOutput.text(error.responseJSON.modelState.cartCouponCode[0])
      })
      .finally(() => $fieldset.removeAttr('disabled'))
  }
}

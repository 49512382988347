import BaseDOMModule from '../core/modules/BaseDOMModule'
import { hasCookie, setCookie, DEFAULT_PATH } from '../core/utils/Cookies'

export default class AcceptCookies extends BaseDOMModule {
  static domEvents = {
    'click [data-accept]': 'onAcceptClick'
  }

  constructor(...args) {
    super(...args)
    this.showCookieBar()
  }

  onAcceptClick() {
    this.$element.hide()
    setCookie(this.options.cookieName, Date.now(), Infinity, DEFAULT_PATH)
  }

  showCookieBar() {
    if (!hasCookie(this.options.cookieName)) {
      this.$element.show()
    }
  }
}

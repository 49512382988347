$(function(ReadMoreLess){

  showmore = $('.article__readmore-btn').data('showmoretext');
  showless = $('.article__readmore-btn').data('showlesstext');    
  $(".article__text-content").each(function(){
    
    if($(this).height() < $(this)[0].scrollHeight){
      $(this).parent().find(".article__readmore-btn").show();
      $(this).toggleClass("truncate");
    }
  });
  $(".article__readmore-btn").click(function(){
    if($(this).prev().hasClass("truncate")) {
    				$(this).parent().find(".article__text-content").css("max-height", $(this).parent().find(".article__text-content")[0].scrollHeight);
            $(this).children('a').text(showless);
            
        } else {
    				$(this).parent().find(".article__text-content").css("max-height", "10.5em");
            $(this).children('a').text(showmore);
        }
    $(this).toggleClass("readmore readless");
    $(this).prev().toggleClass("truncate");

  });
});

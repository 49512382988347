import BaseService from '../../core/services/BaseService'
import ClientData from '../ClientData'

const isEqualName = (nameA, nameB) =>
  nameA && nameB && nameA.trim().toLowerCase() === nameB.trim().toLowerCase()

const uniqueArray = array =>
  Object.keys(array.reduce((obj, item) => ({ ...obj, [item]: item }), {}))

class WasherSelectorService extends BaseService {
  clientDataKey = 'washerSelector'

  getWashers() {
    return ClientData.getPageModelData(`${this.clientDataKey}.washers`)
  }

  getMaterials() {
    return ClientData.getPageModelData(`${this.clientDataKey}.materials`)
  }

  getSurfaceMaterialTranslations() {
    return ClientData.getPageModelData(
      `${this.clientDataKey}.contactSurfaceTranslations`
    )
  }

  getClearanceHoleTranslations() {
    return ClientData.getPageModelData(
      `${this.clientDataKey}.clearanceHoleTranslations`
    )
  }

  getAvailableContactSurfaces() {
    const surfaceMaterialValues = uniqueArray(
      this.getMaterials().reduce(
        (result, current) => [...result, ...current.contactSurfaceMaterials],
        []
      )
    )

    surfaceMaterialValues.push(
      surfaceMaterialValues.splice(surfaceMaterialValues.indexOf('Other'), 1)[0]
    )

    return surfaceMaterialValues.map(this.translateSurfaceMaterial.bind(this))
  }

  getAvailableBoltSizes(contactSurface, unitSystem) {
    const materials = this.getAvailableMaterialsByContactSurface(contactSurface)
    const washers = this.getWashers()

    return uniqueArray(
      materials
        .map(material =>
          washers
            .filter(washer => washer.material === material)
            .map(washer => washer[unitSystem])
        )
        .reduce((result, current) => [...result, ...current], [])
    )
  }

  getAvailableMaterialsByContactSurface(contactSurface) {
    return this.getMaterials()
      .filter(material =>
        material.contactSurfaceMaterials.find(s => s === contactSurface)
      )
      .map(material => material.name)
  }

  getAvailableMaterials(size, unitSystem) {
    const foundWashers = this.getWashers()
      .filter(washer => isEqualName(washer[unitSystem], size))
      .map(washer => washer.material)

    return uniqueArray(foundWashers).map(this.translateMaterial.bind(this))
  }

  getAvailableOuterDiameters(size, unitSystem, material) {
    let availableOuterDiamaters = this.getWashers()
      .filter(
        washer =>
          isEqualName(washer[unitSystem], size) &&
          isEqualName(washer.material, material)
      )
      .map(washer => washer.outerDiameterType)
    return availableOuterDiamaters
  }

  getAvailableClearanceHoles(size, unitSystem, material) {
    let availableOuterDiamaters = this.getAvailableOuterDiameters(
      size,
      unitSystem,
      material
    )

    return availableOuterDiamaters.map(this.translateClearanceHole.bind(this))
  }

  getWasher(size, unitSystem, material, outerDiameter) {
    return this.getWashers().find(
      washer =>
        isEqualName(washer[unitSystem], size) &&
        isEqualName(washer.outerDiameterType, outerDiameter) &&
        isEqualName(washer.material, material)
    )
  }

  translateSurfaceMaterial(surfaceMaterial) {
    const surfaceMaterialTranslations = this.getSurfaceMaterialTranslations()
    const translation = (surfaceMaterialTranslations || []).find(
      x => x.key === surfaceMaterial
    )
    return {
      value: surfaceMaterial,
      text: translation ? translation.value : surfaceMaterial
    }
  }

  translateClearanceHole(outerDiameterType) {
    const clearanceHoleTranslations = this.getClearanceHoleTranslations()
    const translation = (clearanceHoleTranslations || []).find(
      x => x.key === outerDiameterType
    )
    return {
      value: outerDiameterType,
      text: translation ? translation.value : outerDiameterType
    }
  }

  translateMaterial(material) {
    const materialTranslations = this.getMaterials()
    const translation = (materialTranslations || []).find(
      x => x.name === material
    )

    return {
      value: material,
      text: translation.materialName || material
    }
  }
}

export default new WasherSelectorService()

import BaseDOMModule from '../../core/modules/BaseDOMModule'
import Console from '../../core/utils/Console'
import CartService from '../../services/commerce/CartService'
import PDPService from '../../services/commerce/PDPService'

export default class AddToCart extends BaseDOMModule {
  static domEvents = {
    submit: 'onSubmit',
    'blur [data-input-quantity]': 'onInputQuantityBlur',
    'click [data-update-quantity]': 'onUpdateQuantity'
  }

  constructor(...args) {
    super(...args)

    PDPService.on(PDPService.VARIANT_UPDATE, this.onVariantUpdate.bind(this))
  }

  onSubmit(event) {
    event.preventDefault()

    const sku = this.$('[data-input-sku]').val()
    const language = this.$('[data-input-language]').val()
    const quantity = this.$('[data-input-quantity]').val()

    this.setLoading(true)

    CartService.addItem(sku, language, quantity)
      .then(() => {
        this.updateQuantity(1, true)
      })
      .catch(error => this.showError(error))
      .finally(() => this.setLoading(false))
  }

  onInputQuantityBlur() {
    const $quantity = this.$('[data-input-quantity]')
    const parsedQuantity = parseInt($quantity.val(), 10)
    if (Number.isNaN(parsedQuantity)) {
      $quantity.val(1)
    }
  }

  onUpdateQuantity(event, $button) {
    const deltaValue = parseInt($button.data('update-quantity'), 10)
    this.updateQuantity(deltaValue)
  }

  onVariantUpdate(sku) {
    this.updateSku(sku)
  }

  setLoading(isLoading) {
    // toggle some loading state
  }

  showError(error) {
    // show error in DOM
    Console.error(error)
  }

  updateQuantity(deltaValue, setDeltaValue = false) {
    const $quantity = this.$('[data-input-quantity]')
    const currentQuantity = parseInt($quantity.val(), 10)
    $quantity.val(
      setDeltaValue === true
        ? deltaValue
        : Math.max(1, currentQuantity + deltaValue)
    )
  }

  updateSku(sku) {
    this.$('[data-input-sku]').val(sku)
  }
}

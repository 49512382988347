import BaseService from '../core/services/BaseService'
import Api from './Api'
import { buildQueryString } from '../core/utils/query'

class SearchService extends BaseService {
  search(searchValue, brandIds, categoryIds, pageNumber, pageSize) {
    const queryObject = {
      q: searchValue,
      take: pageSize,
      skip: pageSize * pageNumber
    }

    if (brandIds && brandIds.length > 0) {
      queryObject.brand = brandIds.join(',')
    }

    if (categoryIds && categoryIds.length > 0) {
      queryObject.cat = categoryIds.join(',')
    }

    const requestQueryString = buildQueryString(queryObject)

    const requestPath = `/search/search?${requestQueryString}`

    return Api.get(requestPath)
  }
}

export default new SearchService()

import BaseService from '../core/services/BaseService'
import Api from './Api'

class CountryRedirectService extends BaseService {
  getCountrySelection() {
    return Api.get('/CountryRedirectSelection/GetCountrySelection')
  }
}

export default new CountryRedirectService()

import $ from 'jquery'
import Console from '../utils/Console'

export default class Bootstrapper {
  moduleDefinitions = {}
  modules = []

  start() {
    this.initializeAllPageModules()
  }

  addModuleDefinitions(moduleDefinitions) {
    Object.keys(moduleDefinitions).forEach(moduleName => {
      const moduleConstructor = moduleDefinitions[moduleName]
      if (!moduleName) {
        Console.error('No moduleName found on moduleDefinition')
        return
      }

      this.moduleDefinitions[moduleName] = moduleConstructor
    })
  }

  initializeAllPageModules() {
    const $pageModules = $('[data-module]')

    $pageModules.each((index, moduleElement) => {
      const $moduleElement = $(moduleElement)

      const shouldNotInititalize =
        $moduleElement.data('module-no-init') !== undefined
      if (shouldNotInititalize) {
        return
      }

      this.initializeDOMModule($moduleElement)
    })
  }

  initializeDOMModule($moduleElement) {
    const moduleName = $moduleElement.data('module')
    if (!moduleName) {
      Console.error(
        `Tried to initialize [data-module-name] element without a value`
      )
      return
    }

    const ModuleConstructor = this.moduleDefinitions[moduleName]
    if (!ModuleConstructor) {
      Console.error(`No module with name "${moduleName}" found in Bootstrapper`)
      return
    }

    const moduleElementOptions = $moduleElement.data()
    const moduleInstanceOptions = { ...moduleElementOptions }

    const moduleInstance = new ModuleConstructor(
      this,
      $moduleElement,
      moduleInstanceOptions
    )

    this.modules.push(moduleInstance)
  }

  getPageModule(ModuleConstructor) {
    return this.modules.find(module => module instanceof ModuleConstructor)
  }
}

import Bootstrapper from '../core/setup/Bootstrapper'

// Services
import '../services/commerce/AnalyticsEcommerceService'

import AcceptCookies from '../modules/AcceptCookies'
import TeaserListing from '../modules/TeaserListing'
import CADDownload from '../modules/CADDownload'
import Contact from '../modules/Contact'
import CountryRedirect from '../modules/CountryRedirect'
import CountrySelection from '../modules/CountrySelection'
import CountrySelectorLink from '../modules/CountrySelectorLink'
import ContentToggleContainer from '../modules/ContentToggleContainer'
import ClickDimensionForm from '../modules/ClickDimensionForm'
import Dialog from '../modules/Dialog'
import DialogLink from '../modules/DialogLink'
import DocumentDownload from '../modules/DocumentDownload'
import Explore from '../modules/Explore'
import FitImage from '../modules/FitImage'
import HeaderScroll from '../modules/HeaderScroll'
import HeaderSearch from '../modules/HeaderSearch'
import ArticleTextFade from '../modules/ArticleTextFade'
// import NavigationMenu from '../modules/NavigationMenu'
import MenuNavigation from '../modules/MenuNavigation'
import Share from '../modules/Share'
import Search from '../modules/Search'
import SearchPage from '../modules/SearchPage'
import SubNavigation from '../modules/SubNavigation'
import FormExpand from '../modules/FormExpand'
import FloatingContactForm from '../modules/FloatingContactForm'
import Tabs from '../modules/Tabs'
import TeaserCarousel from '../modules/TeaserCarousel'
import HeroCarousel from '../modules/HeroCarousel'
import ToggleNavigationMenu from '../modules/ToggleNavigationMenu'
import ToggleTilesExpand from '../modules/ToggleTilesExpand'
import WebShare from '../modules/WebShare'
import Manual from '../modules/Manual'
import ManualFooter from '../modules/ManualFooter'
import RedirectDropdown from '../modules/RedirectDropdown'
import Tables from '../modules/Tables'
import PrintPage from '../modules/PrintPage'
import PageNumber from '../modules/PageNumber'
import Print from '../modules/Print'
import BackToTopButton from '../modules/BackToTopButton'
import Hotspots from '../modules/Hotspots'
import FAQ from '../modules/FAQ'
import FadeInText from '../modules/FadeInText'
import Carousel from '../modules/Carousel'

import AddToCart from '../modules/commerce/AddToCart'
import GoToCheckout from '../modules/commerce/GoToCheckout'
import CartLineItems from '../modules/commerce/CartLineItems'
import HeaderCart from '../modules/commerce/HeaderCart'
import HeaderCartDetails from '../modules/commerce/HeaderCartDetails'
import HeaderCartToggleContainer from '../modules/commerce/HeaderCartToggleContainer'
import WasherSelectorGuide from '../modules/commerce/WasherSelectorGuide'
import WasherSelectorQuick from '../modules/commerce/WasherSelectorQuick'
import NlWasherProductInformation from '../modules/commerce/NlWasherProductInformation'

import CheckoutForm from '../modules/commerce/checkout/CheckoutForm'
import CheckoutCouponCode from '../modules/commerce/checkout/CheckoutCouponCode'
import CheckoutShippingMethods from '../modules/commerce/checkout/CheckoutShippingMethods'
import CheckoutSummary from '../modules/commerce/checkout/CheckoutSummary'
import CheckoutVAT from '../modules/commerce/checkout/CheckoutVAT'

import PDPChangeVariant from '../modules/commerce/pdp/PDPChangeVariant'
import PDPImageCarousel from '../modules/commerce/pdp/PDPImageCarousel'
import PDPVariantChangeListener from '../modules/commerce/pdp/PDPVariantChangeListener'
import PDPDescriptionAccordion from '../modules/commerce/pdp/PDPDescriptionAccordion'

import { initializeLazyImages } from '../utils/lazyImages'
import { checkAndApplySvgSpritesheetFixForIE } from '../utils/svgSpriteSheetFixForIE'

const bootstrapper = new Bootstrapper()

bootstrapper.addModuleDefinitions({
  AcceptCookies,
  TeaserListing,
  CADDownload,
  Contact,
  CountryRedirect,
  CountrySelection,
  CountrySelectorLink,
  ContentToggleContainer,
  ClickDimensionForm,
  Dialog,
  DialogLink,
  DocumentDownload,
  Explore,
  FloatingContactForm,
  FormExpand,
  FitImage,
  HeaderScroll,
  HeaderSearch,
  ArticleTextFade,
  // NavigationMenu,
  MenuNavigation,
  Share,
  Search,
  SearchPage,
  SubNavigation,
  Tabs,
  TeaserCarousel,
  HeroCarousel,
  ToggleNavigationMenu,
  ToggleTilesExpand,
  WebShare,
  Manual,
  ManualFooter,
  RedirectDropdown,
  Tables,
  PrintPage,
  PageNumber,
  Print,
  BackToTopButton,
  Hotspots,
  FadeInText,
  Carousel,

  AddToCart,
  GoToCheckout,
  CartLineItems,
  HeaderCart,
  HeaderCartDetails,
  HeaderCartToggleContainer,
  WasherSelectorGuide,
  WasherSelectorQuick,
  NlWasherProductInformation,

  CheckoutForm,
  CheckoutCouponCode,
  CheckoutShippingMethods,
  CheckoutSummary,
  CheckoutVAT,

  PDPChangeVariant,
  PDPImageCarousel,
  PDPVariantChangeListener,
  PDPDescriptionAccordion
})

initializeLazyImages()
checkAndApplySvgSpritesheetFixForIE()

export default bootstrapper

import BaseService from '../../core/services/BaseService'
import CheckoutService from './CheckoutService'
import { getLineItemBySku } from './CartService.utils'
import ClientData from '../ClientData'
import Api from '../Api'

class CartService extends BaseService {
  CART_UPDATED = 'cart.update'
  CART_ADD_ITEM = 'cart.add.item'

  CART_ITEM_ADDED = 'cart.item.added'
  CART_ITEM_REMOVED = 'cart.item.removed'
  CART_ITEM_CHANGED_QUANTITY = 'cart.item.changed.quantity'

  currentCartLineItemState =
    ClientData.getPageModelData('initialCart') || {}

  constructor(...args) {
    super(...args)
    CheckoutService.on(
      CheckoutService.CHECKOUT_DETAILS_UPDATED,
      this.onCheckoutDetailsUpdate.bind(this)
    )
  }

  _emitAndUpdateCurrentCart(cart) {
    this.currentCartLineItemState = cart
    this.emit(this.CART_UPDATED, cart)
  }

  onCheckoutDetailsUpdate(checkoutDetails) {
    this.emit(this.CART_UPDATED, checkoutDetails.cartDetails)
  }

  async addItem(sku, language, quantity) {
    this.emit(this.CART_ADD_ITEM)
    const previousItem = getLineItemBySku(sku, this.currentCartLineItemState)

    const updatedCart = await Api.post('/cart/add', { sku, language, quantity })
    this._emitAndUpdateCurrentCart(updatedCart)

    const item = getLineItemBySku(sku, updatedCart)
    this.emit(this.CART_ITEM_ADDED, item, previousItem, {
      currency: updatedCart.currency
    })
    window.dispatchEvent(new CustomEvent('cartCountChanged'))
  }

  async removeItem(sku) {
    const checkoutDetails = await Api.post('/cart/changeItem', {
      sku,
      quantity: 0
    })

    // Emit event for remove line item before update cart state
    const item = getLineItemBySku(sku, this.currentCartLineItemState)
    this.emit(this.CART_ITEM_REMOVED, item, {
      currency: this.currentCartLineItemState.currency
    })
    window.dispatchEvent(new CustomEvent('cartCountChanged'))

    this._emitAndUpdateCurrentCart(checkoutDetails.cartDetails)
  }

  async updateQuantity(sku, quantity) {
    const checkoutDetails = await Api.post('/cart/changeItem', {
      sku,
      quantity
    })

    const item = getLineItemBySku(sku, this.currentCartLineItemState)
    this.emit(
      this.CART_ITEM_CHANGED_QUANTITY,
      { ...item, quantity },
      {
        currency: this.currentCartLineItemState.currency,
        previousQuantity: item.quantity,
        newQuantity: quantity
      }
    )
    window.dispatchEvent(new CustomEvent('cartCountChanged'))

    this._emitAndUpdateCurrentCart(checkoutDetails.cartDetails)
    CheckoutService.triggerCheckoutUpdate(checkoutDetails)
  }

  async getCurrentCartState() {
    const cartDetails = await Api.get('/cart/getCartState')
    this._emitAndUpdateCurrentCart(cartDetails)
  }
}

export default new CartService()

import $ from 'jquery'

const locale = (window.NordLock ? window.NordLock.currentLocale : (window[0] && window[0].NordLock ? window[0].NordLock.currentLocale : undefined));

class Api {
  request(method, apiPath, data = null, options = null, appendApiPath = true) {
    const separator = apiPath[0] === '/' ? '' : '/'

    return $.ajax({
      url: `${appendApiPath ? '/api' : ''}${separator}${apiPath}`,
      method,
      data,
      ...options,
      headers: {
        'X-Locale': locale
      }
    })
  }

  get(apiPath, options = null, appendApiPath = true) {
    return this.request('get', apiPath, null, options, appendApiPath)
  }

  post(apiPAth, data, options = null, appendApiPath = true) {
    return this.request('post', apiPAth, data, options, appendApiPath)
  }
}

const api = new Api()
if (process.env.NODE_ENV === 'development') {
  window.api = api
}

export default api

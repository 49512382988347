import BaseDOMModule from '../../../core/modules/BaseDOMModule'
import CartService from '../../../services/commerce/CartService'
import CheckoutService from '../../../services/commerce/CheckoutService'
import { renderDataObjectInElement } from '../../../core/utils/templateEngine'

export default class CheckoutSummary extends BaseDOMModule {
  constructor(...args) {
    super(...args)

    CartService.on(CartService.CART_UPDATED, this.onCartUpdate.bind(this))

    CheckoutService.on(
      CheckoutService.CHECKOUT_DETAILS_UPDATED,
      this.onCheckoutDetailsUpdate.bind(this)
    )
  }

  onCartUpdate(cart) {
    this.updateSummaryDetails(cart.summaryDetails, cart.isIncludingTax)
  }

  onCheckoutDetailsUpdate(checkoutDetails) {
    this.updateSummaryDetails(
      checkoutDetails.cartDetails.summaryDetails,
      checkoutDetails.isIncludingTax
    )
  }

  updateSummaryDetails(summaryDetails, isIncludingTax) {
    renderDataObjectInElement(this.$element, {
      vatLabel: {
        _attr: {
          style: !isIncludingTax ? 'display:none;' : ''
        }
      },
      noVatLabel: {
        _attr: {
          style: isIncludingTax ? 'display:none;' : ''
        }
      },
      discountContainer: {
        _attr: {
          style:
            parseFloat(
              summaryDetails.orderDiscountTotal.replace(/[^0-9.,]/, '')
            ) < 1
              ? 'display:none;'
              : ''
        }
      },
      totalExVat: summaryDetails.subTotalShippingTotal,
      discount: summaryDetails.orderDiscountTotal,
      subtotal: summaryDetails.subTotal,
      delivery: summaryDetails.shippingTotal,
      vat: summaryDetails.taxTotal,
      total: summaryDetails.cartTotal
    })
  }
}

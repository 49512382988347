import BaseDOMModule from '../core/modules/BaseDOMModule'
import ContentToggleService from '../services/ContentToggleService'

export default class HeaderSearch extends BaseDOMModule {
  observer = null

  constructor(...args) {
    super(...args)

    ContentToggleService.on(
      ContentToggleService.CONTENT_EXPAND,
      this.onParentContentToggle.bind(this)
    )
  }

  onParentContentToggle(moduleName, expand) {
    if (!moduleName === this.moduleName && expand) {
      return
    }

    this.onHeaderSearchExpanded()
  }

  onHeaderSearchExpanded() {
    this.$element.find('[data-search-input]').focus()
  }
}

import BaseDOMModule from '../../core/modules/BaseDOMModule'
import HeaderCartService from '../../services/commerce/HeaderCartService'
import CartService from '../../services/commerce/CartService'

export default class HeaderCartToggleContainer extends BaseDOMModule {
  constructor(...args) {
    super(...args)

    HeaderCartService.on(
      HeaderCartService.CART_ADD_ITEM,
      this.onCartItemAdded.bind(this)
    )
    window.addEventListener(
      'headerCartToggle',
      this.onToggleExpandClick.bind(this)
    )
  }

  onCartItemAdded() {
    const $cartDetails = this.$element.find('[data-toggle-content]')
    const $cartDetailsLinks = $cartDetails.find(
      '[data-header-cart-details-links]'
    )
    const $this = this.$element

    const newCount = CartService.getCurrentCartState()
    // Update the React component's cart item count
    window.updateReactCartCount(newCount)

    $cartDetailsLinks.hide()
    $cartDetails.fadeIn(50, function() {
      $this.addClass('content-toggle-container--is-expanded-no-overlay')
      $cartDetails.delay(2000).fadeOut(400, function() {
        $this.removeClass('content-toggle-container--is-expanded-no-overlay')
        $cartDetailsLinks.show()
      })
    })
  }

  static domEvents = {
    'click [data-toggle-expand]': 'onToggleExpandClick',
    'click [data-click-overlay]': 'onClickOverlayClick'
  }

  onToggleExpandClick() {
    this.$element.find('[data-toggle-content]').show()
    this.toggleExpand()
  }

  onClickOverlayClick() {
    this.toggleExpand(false)
    this.$element.find('[data-toggle-content]').hide()
  }

  toggleExpand(forceState = undefined) {
    this.$element.toggleClass(this.options.expandClass, forceState)
  }

  destroy() {
    window.removeEventListener(
      'headerCartToggle',
      this.onToggleExpandClick.bind(this)
    )
    super.destroy()
  }
}

import BaseDOMModule from '../../../core/modules/BaseDOMModule'
import PDPService from '../../../services/commerce/PDPService'
import { renderDataObjectInElement } from '../../../core/utils/templateEngine'

export default class PDPVariantChangeListener extends BaseDOMModule {
  constructor(...args) {
    super(...args)
    PDPService.on(PDPService.VARIANT_UPDATE, this.onVariantUpdate.bind(this))
  }

  onVariantUpdate(variantSku) {
    const variantData = PDPService.getVariantData(variantSku)

    renderDataObjectInElement(this.$element, {
      variantName: variantData.name,
      variantPrice: variantData.listingPrice,
      variantDiscountPrice: variantData.discountedPrice
    })
  }
}

import BaseService from '../../core/services/BaseService'
import MarketService from '../commerce/MarketService'
import ClientData from '../ClientData'
import Api from '../Api'

class CheckoutService extends BaseService {
  clientDataKey = 'checkoutPage'
  static addCouponEndpoint = '/checkout/AddCouponCode'
  static removeCouponEndpoint = '/checkout/RemoveCouponCode'
  static setVatNumberEndpoint = '/checkout/SetVatNumber'
  static setSalesTaxNumber = '/checkout/SetSalesTaxNumber'
  static updateDeliveryDetailsEndpoint = '/checkout/Update'

  CHECKOUT_DETAILS_UPDATED = 'checkout-details.update'
  CHECKOUT_COUNTRY_UPDATED = 'checkout-country.update'

  constructor(...args) {
    super(...args)

    MarketService.on(
      MarketService.MARKET_UPDATE,
      this.onMarketInformationUpdate.bind(this)
    )
  }

  getStateCountries() {
    return ClientData.getPageModelData(`${this.clientDataKey}.stateCountries`)
  }

  getSalesTaxCountries() {
    return ClientData.getPageModelData(
      `${this.clientDataKey}.salesTaxCountries`
    )
  }

  getStatesForCountry(country) {
    const list = this.getStateCountries().find(x => x.country === country)
    if (list) return list.states
  }

  triggerCheckoutUpdate(checkoutDetails) {
    this.emit(this.CHECKOUT_DETAILS_UPDATED, checkoutDetails)
  }

  onMarketInformationUpdate({ checkoutDetails }) {
    this.emit(this.CHECKOUT_DETAILS_UPDATED, checkoutDetails)
  }

  async postRequestAndEmitResponse(endpoint, data) {
    const updatedCheckoutDetails = await Api.post(endpoint, data)
    this.emit(this.CHECKOUT_DETAILS_UPDATED, updatedCheckoutDetails)
  }

  addCouponCode(couponCode) {
    return this.postRequestAndEmitResponse(CheckoutService.addCouponEndpoint, {
      code: couponCode
    })
  }

  removeCouponCode(couponCode) {
    return this.postRequestAndEmitResponse(CheckoutService.addCouponEndpoint, {
      code: couponCode
    })
  }

  setVatNumber(vatNumber) {
    return this.postRequestAndEmitResponse(
      CheckoutService.setVatNumberEndpoint,
      { vatNumber }
    )
  }

  setSalesTaxNumber(salesTaxNumber, requestTaxExemption) {
    return this.postRequestAndEmitResponse(CheckoutService.setSalesTaxNumber, {
      salesTaxNumber,
      requestTaxExemption
    })
  }

  updateDeliveryDetails(deliveryDetails) {
    return this.postRequestAndEmitResponse(
      CheckoutService.updateDeliveryDetailsEndpoint,
      deliveryDetails
    )
  }

  changeCountry(countryCode, countryName) {
    this.emit(this.CHECKOUT_COUNTRY_UPDATED, countryCode, countryName)
  }
}

export default new CheckoutService()

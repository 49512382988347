import $ from 'jquery'
import BaseDOMModule from '../core/modules/BaseDOMModule'

const $window = $(window)

export default class SubNavigation extends BaseDOMModule {
  static domEvents = {
    'click a': 'onLinkClick',
    'change [navigation-dropdown]': 'onDropDownChange'
  }

  localStorageDistanceToTopKey = 'sub-navigation-distance-to-top'
  localStorageDateKey = 'sub-navigation-date'

  constructor(...args) {
    super(...args)

    this.checkPreviousScrollPosition()
  }

  onLinkClick() {
    this.saveScrollPosition()
  }

  onDropDownChange(event) {
    this.saveScrollPosition()
    window.location.replace(event.currentTarget.value)
  }

  checkPreviousScrollPosition() {
    const previousDateStr = window.localStorage.getItem(
      this.localStorageDateKey
    )
    const previousDistanceStr = window.localStorage.getItem(
      this.localStorageDistanceToTopKey
    )

    if (previousDateStr && previousDistanceStr) {
      const previousDate = new Date(parseInt(previousDateStr, 10))
      const now = Date.now()

      const maxTimeBetween = 1000 * 10 // 10 seconds
      if (now - previousDate <= maxTimeBetween) {
        const previousDistance = parseInt(previousDistanceStr, 10)
        const currentDistance = this.getElementDistanceToTop()

        $window.scrollTop(currentDistance - previousDistance)
      }
    }

    window.localStorage.removeItem(this.localStorageDateKey)
    window.localStorage.removeItem(this.localStorageDistanceToTopKey)
  }

  saveScrollPosition() {
    window.localStorage.setItem(this.localStorageDateKey, Date.now())
    window.localStorage.setItem(
      this.localStorageDistanceToTopKey,
      this.getElementDistanceToTop()
    )
  }

  getElementDistanceToTop() {
    const element = this.$element[0]
    const elementBounds = element.getBoundingClientRect()
    return elementBounds.top
  }
}

$(".accordion-container__content").css("display", "none");
$(".js-showmorebtn").css("display", "none");

$(".accordion-container__title").click(function () {
    $(".accordion-container__title").not(this).removeClass("open");
    $(".accordion-container__title").not(this).next().slideUp(300);
    $(this).toggleClass("open");
    $(this).next().slideToggle(300);
});

$('.js-accordion').each(function (i) {
    // console.log("have accordion");
    // Setup every list
    var section = $(this).data('section');
    // console.log("section ", section);
    var parentSection = $('.section-' + section);
    // console.log("parentSection ", parentSection);
    var list = parentSection.find(".js-accordion-container__title__heading");
    // console.log("list ", list);
    var numToShow = 5;
    var button = parentSection.find(".js-showmorebtn");
    // console.log("button ", button);
    var numInList = list.length;
    list.hide();
    if (numInList > numToShow) {
        // console.log("numInList ", numInList);
        // console.log("numToShow ", numToShow);
        button.show();
    }
    list.slice(0, numToShow).show();

    button.bind('click', function () {
        var button = parentSection.find(".js-showmorebtn");
        var showing = list.filter(':visible').length;
        list.slice(showing - 1, showing + numToShow).fadeIn();
        var nowShowing = list.filter(':visible').length;
        if (nowShowing >= numInList) {
            button.hide();
        }

    });
    button = null;

})
import BaseTemplateModule from '../core/modules/BaseTemplateModule'
import FilterService from '../services/FilterService'
import { yearMonthDateFormat } from '../core/utils/dateFormat'

export default class TeaserListing extends BaseTemplateModule {
  static domEvents = {
    'change [data-tree-select-filter]': 'onFilterChange',
    'click [data-remove-filter]': 'onRemoveFilterClick'
  }

  selectedFilters = []

  constructor(...args) {
    super(...args)
    this.renderFilters()
    this.renderResultCount()
  }

  onFilterChange(event, $filter) {
    const filterValue = $filter.val()
    const filterLevel = $filter.data('level')

    this.setFilterValue(filterLevel, filterValue)
  }

  onRemoveFilterClick(event, $removeButton) {
    const $filterKey = $removeButton.parent().find('[data-filter-key]')
    const filterKey = $filterKey.data('filter-key').toString()

    const filterLevel = this.selectedFilters.findIndex(x => x === filterKey)
    this.setFilterValue(filterLevel, null)
  }

  setFilterValue(filterLevel, filterValue) {
    this.selectedFilters[filterLevel] = filterValue

    const temp = []
    for (var i = filterLevel; i > -1; i--) {
      temp[i] = this.selectedFilters[i]
    }

    // Filter out nulls
    this.selectedFilters = temp.filter(x => x)

    this.renderAll()
  }

  renderFilters() {
    const rootTree = FilterService.getPageListingTree(this.options.dataId)
    const filters = [
      ...FilterService.generateFilters(rootTree, [...this.selectedFilters])
    ]

    this.renderFilterResults(filters)
  }

  renderAssets() {
    const rootTree = FilterService.getPageListingTree(this.options.dataId)
    const currentTree = FilterService.getTreeLevelByFilters(
      rootTree,
      this.selectedFilters
    )

    const assets = [...FilterService.flattenTreeAssets(currentTree)]
    this.renderAssetsResult(assets)
    return assets.length
  }

  renderAll() {
    this.renderFilters()
    const numOfAssets = this.renderAssets()
    this.renderResultCount(numOfAssets)
  }

  renderResultCount(numberOfAssets) {
    const baseFilterKey = this.selectedFilters[0]
    const baseFilterName = FilterService.getPageFilterNameByKey(
      this.options.dataId,
      baseFilterKey
    )

    let filterResultText = this.options.filterResultText
    var result =
      numberOfAssets !== undefined
        ? numberOfAssets
        : this.options.initialResultCount

    filterResultText = filterResultText.replace(
      '{value}',
      `<span class='tree-filter__results-filter-result-number'>${result}</span>`
    )
    this.$('[data-result-count]').html(filterResultText)

    this.$('[data-base-filter-name]').text(baseFilterName)
  }

  renderAssetsResult(assets) {
    this.renderItems('articleItem', assets, item => ({
      targetUrl: { _attr: { href: item.teaserTargetUrl } },
      image: { _attr: { src: item.teaserImageUrl } },
      title: item.teaserTitle,
      textDate: yearMonthDateFormat(new Date(item.teaserDate)),
      text: item.teaserText,
      ctaLinkText: item.teaserLinkText
    }))
  }

  renderFilterResults(filters) {
    const templateElement = this.getTemplate('selectItem').$element.clone()
    const defaultOption = templateElement.find('[data-template-no-remove]')

    this.renderItems('selectItem', filters, filterItem => {
      const selectedFilterValue = this.selectedFilters[filterItem.level]

      let placeholderOptionHTML = defaultOption.prop('outerHTML')
      if (selectedFilterValue) {
        placeholderOptionHTML = placeholderOptionHTML.replace(
          /selected(="[^"]*")?/i,
          ''
        )
      }

      const filterOptions = filterItem.list.map(
        ({ value, text }) =>
          `<option value="${value}" ${
            value.toString() === selectedFilterValue ? 'selected' : ''
          }>${text}</option>`
      )

      return {
        items: {
          _attr: { 'data-level': filterItem.level },
          _html: [placeholderOptionHTML, ...filterOptions]
        }
      }
    })

    this.renderItems('selectedFilter', filters, filterItem => {
      const filterKey = this.selectedFilters[filterItem.level]
      const filterName = FilterService.getPageFilterNameByKey(
        this.options.dataId,
        filterKey
      )

      if (!filterName) {
        return null
      }

      return {
        filterName: {
          _attr: { 'data-filter-key': filterKey },
          _text: filterName
        }
      }
    })
  }
}

import BaseTemplateModule from '../../core/modules/BaseTemplateModule'
import DialogService from '../../services/DialogService'
import WasherSelectorService from '../../services/commerce/WasherSelectorService'
import capitalize from '../../core/utils/fn/capitalize'
import { renderDataObjectInElement } from '../../core/utils/templateEngine'
import Console from '../../core/utils/Console'

const GuideSteps = {
  ContactMaterial: 'contactMaterial',
  BoltSize: 'boltSize',
  BoltMaterial: 'boltMaterial',
  OuterDiameterType: 'outerDiameterType',
  Result: 'result'
}

const stepOrder = [
  GuideSteps.ContactMaterial,
  GuideSteps.BoltSize,
  GuideSteps.BoltMaterial,
  GuideSteps.OuterDiameterType,
  GuideSteps.Result
]

export default class WasherSelectorGuide extends BaseTemplateModule {
  static domEvents = {
    'click [data-start-guide]': 'onStartGuide',
    'click [data-close-guide]': 'onCloseGuide',
    'click [data-back]': 'onBackClick',
    'input [data-step-value]': 'onStepValueChange',
    'change [data-select-unit-system]': 'onUnitSystemChange',
    'change [data-step-material]': 'onStepMaterialChange',
    'change [data-step-value][type="radio"]': 'onStepValueChange',
    'submit [data-step]': 'onStepSubmit'
  }

  currentStep = GuideSteps.ContactMaterial

  selectedValues = {
    [GuideSteps.ContactMaterial]: null,
    [GuideSteps.BoltSize]: null,
    [GuideSteps.BoltMaterial]: null,
    [GuideSteps.OuterDiameterType]: null
  }

  onStartGuide() {
    this.startGuide()
  }

  onCloseGuide() {
    this.hideGuide()
  }

  onBackClick() {
    const currentStepIndex = this.getStepIndex(this.currentStep)
    if (currentStepIndex > 0) {
      this.setActiveStep(stepOrder[currentStepIndex - 1])
    }
  }

  onStepValueChange(event, $stepValueControl) {
    const $stepForm = $stepValueControl.closest('[data-step]')
    const stepName = this.getStepFormName($stepForm)

    this.updateStepValidity(stepName)
  }

  onStepMaterialChange(event, $stepValueControl) {
    if ($stepValueControl.val().toLocaleLowerCase() === 'other') {
      DialogService.openDialog({
        title: $stepValueControl.data('other-material-dialog-title'),
        body: $stepValueControl.data('other-material-dialog-body'),
        closeLabel: $stepValueControl.data('other-material-dialog-close')
      })
    }
  }

  onUnitSystemChange(event, $stepValueControl) {
    this.$('[data-step="boltSize"] [data-step-value]').val('')
    this.renderBoltSize()
  }

  onStepSubmit(event, $stepForm) {
    event.preventDefault()

    const stepName = this.getStepFormName($stepForm)
    if (!this.isValidStep(stepName)) {
      return
    }

    this.saveStepFormResult($stepForm)

    const stepIndex = this.getStepIndex(stepName)
    let nextStep = stepOrder[stepIndex + 1]

    // If next step is outer diameter type and only have one option. Select it and go to next step
    if (nextStep === GuideSteps.OuterDiameterType) {
      const availableOuterDiameters = this.getAvailableOuterDiameters()
      if (availableOuterDiameters.length === 1) {
        this.saveStepResult(nextStep, availableOuterDiameters[0].value)
        nextStep = stepOrder[stepIndex + 2]
      }
    }

    this.setActiveStep(nextStep)
  }

  getSelectedUnitSystem() {
    return this.$('[data-select-unit-system]').val()
  }

  hideGuide() {
    this.animateToInactive()
  }

  startGuide() {
    this.animateToActive()
    this.setActiveStep(this.currentStep)
  }

  animateToActive() {
    const {
      $element,
      options: { activeClass, expandedClass }
    } = this

    $element.addClass(activeClass)
    $element.addClass(expandedClass)
  }

  animateToInactive() {
    const {
      $element,
      options: { activeClass, expandedClass }
    } = this

    $element.removeClass(activeClass)
    $element.removeClass(expandedClass)
  }

  setActiveStep(stepName) {
    if (!stepOrder.includes(stepName)) {
      Console.error(`Invalid step namne: ${stepName}`)
      return
    }

    this.renderStep(stepName)
    this.updateStepValidity(stepName)
    this.showStep(stepName)

    this.currentStep = stepName
    this.toggleBackButton()
  }

  showStep(stepName) {
    const { activeStepClass, activeIndicatorItemClass } = this.options
    const $currentStep = this.$(`.${activeStepClass}`)
    const $nextStep = this.getStepForm(stepName)

    $currentStep.removeClass(activeStepClass)
    $nextStep.addClass(activeStepClass)

    const nextStepIndex = stepOrder.indexOf(stepName)
    const $currentIndicatorItem = this.$(`.${activeIndicatorItemClass}`)
    const $nextIndicatorItem = this.$(
      `[data-indicator-item]:nth-child(${nextStepIndex + 1})`
    )

    $currentIndicatorItem.removeClass(activeIndicatorItemClass)
    $nextIndicatorItem.addClass(activeIndicatorItemClass)
  }

  renderStep(stepName) {
    switch (stepName) {
      case GuideSteps.ContactMaterial:
        this.renderContactSurface()
        break
      case GuideSteps.BoltSize:
        this.renderBoltSize()
        break
      case GuideSteps.BoltMaterial:
        this.renderBoltMaterial()
        break
      case GuideSteps.OuterDiameterType:
        this.renderOuterDiameterType()
        break
      case GuideSteps.Result:
        this.renderResult()
        break
    }
  }

  renderContactSurface() {
    this.renderItems(
      'contactMaterialOption',
      this.getAvailableContactSurfaces(),
      item => ({
        _text: item.text,
        _attr: { value: item.value }
      })
    )
  }

  renderBoltSize() {
    this.renderOptions('boltSizeOption', this.getAvailableBoltSizes())
  }

  renderBoltMaterial() {
    this.renderItems(
      'boltMaterialOption',
      this.getAvailableMaterials(),
      item => ({
        _text: item.text,
        _attr: { value: item.value }
      })
    )
  }

  renderOuterDiameterType() {
    this.renderItems(
      'outerDiameterTypeOption',
      this.getAvailableOuterDiameters(),
      item => ({
        stepValue: { _attr: { value: item.value } },
        text: item.text
      })
    )
  }

  renderOptions(templateName, items) {
    this.renderItems(templateName, items, item => ({
      _text: capitalize(item),
      _attr: { value: item }
    }))
  }

  renderResult() {
    const { selectedValues } = this
    const contactMaterial = selectedValues[GuideSteps.ContactMaterial]
    const boltSize = selectedValues[GuideSteps.BoltSize]
    const boltMaterial = selectedValues[GuideSteps.BoltMaterial]
    const outerDiameterType = selectedValues[GuideSteps.OuterDiameterType]
    const unitSystem = this.getSelectedUnitSystem()

    const washer = WasherSelectorService.getWasher(
      boltSize,
      unitSystem,
      boltMaterial,
      outerDiameterType
    )

    const $resultStep = this.getStepForm(GuideSteps.Result)
    renderDataObjectInElement($resultStep, {
      resultContactMaterial: WasherSelectorService.translateSurfaceMaterial(
        contactMaterial
      ).text,
      resultBoltSize: capitalize(boltSize),
      resultBoltMaterial: capitalize(boltMaterial),
      resultOuterDiameterType: WasherSelectorService.translateClearanceHole(
        outerDiameterType
      ).text,
      resultWasher: washer.washerName,
      resultLink: { _attr: { href: washer.url } }
    })
  }

  toggleBackButton() {
    const currentIndex = this.getStepIndex(this.currentStep)
    const $backButton = this.$('[data-back]')

    $backButton.toggleClass(this.options.activeBackClass, currentIndex > 0)
  }

  updateStepValidity(stepName) {
    const isValidStepValue = this.isValidStep(stepName)
    const $stepForm = this.getStepForm(stepName)

    const $nextButton = $stepForm.find('[data-next]')
    $nextButton.attr('disabled', isValidStepValue === false)
  }

  isValidStep(stepName) {
    switch (stepName) {
      case GuideSteps.ContactMaterial:
        return this.isContactMaterialFormValid()
      case GuideSteps.BoltSize:
        return this.isBoltSizeFormValid()
      case GuideSteps.BoltMaterial:
        return this.isBoltMaterialFormValid()
      case GuideSteps.OuterDiameterType:
        return this.isOuterDiameterTypeFormValid()
    }
  }

  isContactMaterialFormValid() {
    return this.$(
      `[data-step="${GuideSteps.ContactMaterial}"] [data-step-value]`
    ).is(':valid')
  }

  isBoltSizeFormValid() {
    const $inputBoltSize = this.$(
      `[data-step="${GuideSteps.BoltSize}"] [data-step-value]`
    )
    const boltSizeValue = $inputBoltSize.val()
    const avaialableBoltSizes = this.getAvailableBoltSizes()
    return avaialableBoltSizes.includes(boltSizeValue)
  }

  isBoltMaterialFormValid() {
    return this.$(
      `[data-step="${GuideSteps.BoltMaterial}"] [data-step-value]`
    ).is(':valid')
  }

  isOuterDiameterTypeFormValid() {
    return (
      this.$(
        `[data-step="${
          GuideSteps.OuterDiameterType
        }"] [data-step-value]:checked`
      ).length === 1
    )
  }

  saveStepFormResult($stepForm) {
    const stepName = $stepForm.data('step')

    const $stepValueControl =
      stepName === GuideSteps.OuterDiameterType
        ? $stepForm.find('[data-step-value]:checked')
        : $stepForm.find('[data-step-value]')
    const stepValue = $stepValueControl.val()

    this.saveStepResult(stepName, stepValue)
  }

  saveStepResult(stepName, stepValue) {
    this.selectedValues[stepName] = stepValue
  }

  getStepForm(stepName) {
    return this.$(`[data-step="${stepName}"]`)
  }

  getStepFormName($stepForm) {
    return $stepForm.data('step')
  }

  getStepIndex(stepName) {
    return stepOrder.indexOf(stepName)
  }

  getAvailableContactSurfaces() {
    return WasherSelectorService.getAvailableContactSurfaces()
  }

  getAvailableBoltSizes() {
    const { selectedValues } = this
    return WasherSelectorService.getAvailableBoltSizes(
      selectedValues[GuideSteps.ContactMaterial],
      this.getSelectedUnitSystem()
    ).filter(bolt => bolt !== null && bolt !== 'null')
  }

  getAvailableMaterials() {
    const { selectedValues } = this
    return WasherSelectorService.getAvailableMaterials(
      selectedValues[GuideSteps.BoltSize],
      this.getSelectedUnitSystem()
    )
  }

  getAvailableOuterDiameters() {
    const { selectedValues } = this
    return WasherSelectorService.getAvailableClearanceHoles(
      selectedValues[GuideSteps.BoltSize],
      this.getSelectedUnitSystem(),
      selectedValues[GuideSteps.BoltMaterial]
    )
  }
}

import ClickDimensionService from '../services/ClickDimensionService'
import BaseForm from './BaseForm'

export default class ClickDimensionForm extends BaseForm {
  static domEvents = BaseForm.domEvents

  successClass = 'click-dimension-form--is-success'

  /* @override */ async submitForm(serializedFormData) {
    await ClickDimensionService.postForm(serializedFormData)
  }

  /* @override */ onPostSuccess() {   
    var redirectEl = document.getElementById('formRedirect');
    if(redirectEl.value === ""){
    this.showSuccess()   
    }else{
      location.href = redirectEl.value;
    } 
  }

  showSuccess() {
    this.$element.addClass(this.successClass)
  }
}

import noop from './fn/noop'

const globalConsole = window.console || {}

export const LogLevels = {
  none: 0,
  error: 1,
  warn: 2,
  info: 3,
  log: 4,
  debug: 5
}

class Console {
  logLevel =
    process.env.NODE_ENV === 'development' ? LogLevels.log : LogLevels.error

  constructor() {
    this.setupConsoleMethods()
  }

  setupConsoleMethods() {
    Object.keys(LogLevels).forEach(logLevelName => {
      const logLevelValue = LogLevels[logLevelName]
      const windowConsoleMethod = globalConsole[logLevelName] || noop

      this[logLevelName] = (...args) => {
        if (this.logLevel < logLevelValue) {
          return
        }

        windowConsoleMethod(...args)
      }
    })
  }

  /* Defined these to make typescript happy */
  error(...args) {}
  warn(...args) {}
  info(...args) {}
  log(...args) {}
  debug(...args) {}
}

export default new Console()

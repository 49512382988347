import $ from 'jquery'
import BaseDOMModule from '../core/modules/BaseDOMModule'
import Breakpoints from '../constants/Breakpoints'

export default class Explore extends BaseDOMModule {
  static domEvents = {
    'click [data-item-link]': 'onItemLinkClick'
  }

  static windowEvents = {
    resize: 'onResize'
  }

  isAccordion = true

  onItemLinkClick(event, $itemLink) {
    $itemLink.blur()
    $itemLink.focus({ preventScroll: true })
    const itemId = $itemLink.data('item-link')
    this.showItem(itemId)
  }

  onResize(event, windowWidth) {
    this.isAccordion = windowWidth < Breakpoints.Medium
    this.makeSureItemIsActiveInNoneAccordion()
  }

  showItem(itemId) {
    const {
      itemActiveClass,
      linkActiveClass,
      linkContainerActiveClass
    } = this.options

    const $currentActiveItem = this.$(`.${itemActiveClass}`)
    const $currentActiveLink = this.$(`.${linkActiveClass}`)
    const $currentActiveLinkContainer = this.$(`.${linkContainerActiveClass}`)

    const $nextActiveItem = this.$(`[data-item-id="${itemId}"]`)
    const $nextActiveLink = this.$(`[data-item-link="${itemId}"]`)
    const $nextActiveLinkContainer = this.$(
      `[data-item-link-container="${itemId}"]`
    )

    const itemWasActive = $nextActiveItem.hasClass(itemActiveClass) === true

    $currentActiveItem.removeClass(itemActiveClass)
    $currentActiveLink.removeClass(linkActiveClass)
    $currentActiveLinkContainer.removeClass(linkContainerActiveClass)

    if (this.isAccordion && itemWasActive) {
      return
    }

    $nextActiveItem.addClass(itemActiveClass)
    $nextActiveLink.addClass(linkActiveClass)
    $nextActiveLinkContainer.addClass(linkContainerActiveClass)
  }

  makeSureItemIsActiveInNoneAccordion() {
    if (this.isAccordion) {
      return
    }

    const { itemActiveClass } = this.options
    const $currentActive = this.$(`.${itemActiveClass}`)
    if ($currentActive.length === 1) {
      return
    }

    const $firstItem = $(this.$('[data-item-id]').get(0))
    const firstItemId = $firstItem.data('item-id')

    this.showItem(firstItemId)
  }
}

import BaseService from '../core/services/BaseService'
import Dialog from '../modules/Dialog'
import CountrySelection from '../modules/CountrySelection'
import bootstrapper from '../setup/NordLockBootstrapper'

class DialogService extends BaseService {
  getDialogModule() {
    return bootstrapper.getPageModule(Dialog)
  }

  openDialog(dialogData) {
    const dialogModule = this.getDialogModule()
    dialogModule.show(dialogData)
  }

  openCountrySelectionDialog() {
    const countrySelectionModule = bootstrapper.getPageModule(CountrySelection)
    if (countrySelectionModule) {
      countrySelectionModule.show()
    }
  }
}

export default new DialogService()

import BaseDOMModule from '../core/modules/BaseDOMModule'
// import NavigationMenu from './NavigationMenu'
import MenuNavigation from './MenuNavigation'
import bootstrapper from '../setup/NordLockBootstrapper'

export default class ToggleNavigationMenu extends BaseDOMModule {
  static domEvents = {
    click: 'onClick'
  }

  onClick(event) {
    event.preventDefault()

    // const navigationMenu = bootstrapper.getPageModule(NavigationMenu)
    const navigationMenu = bootstrapper.getPageModule(MenuNavigation)
    navigationMenu.toggleMenu(this.options.close !== true)
  }
}

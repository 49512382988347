import BaseTemplateModule from '../core/modules/BaseTemplateModule'
import { triggerElementEvent } from 'Core/utils/domEvents'
import CustomDomEventNames from 'Core/constants/CustomDomEventNames'
import { FormEvent } from 'react'

export default class BaseForm extends BaseTemplateModule {
  static domEvents = {
    'submit [data-form]': 'onFormSubmit',
    'click [data-submit-form]': 'onSubmitClick'
  }

  requiredElements: HTMLInputElement[] = this.$(
    '[required]'
  ).toArray() as HTMLInputElement[]

  onSubmitClick(event: MouseEvent) {
    this.unmarkAllErrorControls()

    if (!this.isFormValid()) {
      this.markAllErrorControls()
    }
  }

  async onFormSubmit(event: FormEvent, $formElement: JQuery<HTMLFormElement>) {
    event.preventDefault()

    this.unmarkAllErrorControls()

    if (!this.isFormValid()) {
      this.markAllErrorControls()
      return
    }

    const serializedFormData = $formElement.serialize()

    triggerElementEvent(
      $formElement.get(0),
      CustomDomEventNames.OnFormSerialized,
      serializedFormData
    )

    this.toggleFormDisabled(true)

    try {
      await this.submitForm(serializedFormData)
    } catch (exception) {
      this.toggleFormDisabled(false)
      return
    }

    this.onPostSuccess()

    triggerElementEvent($formElement.get(0), CustomDomEventNames.OnFormSuccess)
  }

  /**
   * Override to define behaviour
   */
  async submitForm(serializedFormData: string) {}

  /**
   * Override to define behaviour
   */
  onPostSuccess() {}

  isFormValid() {
    return this.getAllInvalidElements().length === 0
  }

  isElementValid(formElement: HTMLInputElement) {
    return formElement.validity.valid !== false
  }

  markAllErrorControls() {
    const invalidElements = this.getAllInvalidElements()
    invalidElements.forEach(element => element.classList.add('error'))
  }

  unmarkAllErrorControls() {
    this.requiredElements.forEach(element => element.classList.remove('error'))
  }

  getAllInvalidElements() {
    return this.requiredElements.filter(
      element => !this.isElementValid(element)
    )
  }

  toggleFormDisabled(isDisabled: boolean) {
    this.$('input,select').attr(
      'disabled',
      isDisabled === true ? 'disabled' : null
    )
  }
}

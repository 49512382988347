import BaseDOMModule from '../../core/modules/BaseDOMModule'
import PDPService from '../../services/commerce/PDPService'

export default class NlWasherProductInformation extends BaseDOMModule {
  constructor(...args) {
    super(...args)

    PDPService.on(PDPService.VARIANT_UPDATE, this.onVariantUpdate.bind(this))
  }

  onVariantUpdate(variantSku) {
    const variantData = PDPService.getVariantData(variantSku)

    this.$('[data-variant-code]').text(variantSku)
    this.$('[data-variant-price]').text(variantData.listingPrice)
    this.$('[data-variant-package-amount]').text(
      variantData.information.packageAmount
    )
  }
}

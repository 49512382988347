//This is the javascript for the ProductHorizontalImageSliderBlock
class Carousel {
  constructor(container, items, controls, controlsContainer) {
    this.carouselContainer = container;
    this.carouselControls = controls;
    this.carouselArray = [...items];
    this.carouselControlsContainer = controlsContainer;
  }

  updateGallery() {
    this.carouselArray.forEach(el => {
      el.classList.remove('gallery-item-1');
      el.classList.remove('gallery-item-2');
      el.classList.remove('gallery-item-3');
      el.classList.remove('gallery-item-4');
      el.classList.remove('gallery-item-5');
    });

    this.carouselArray.slice(0, 5).forEach((el, i) => {
      el.classList.add(`gallery-item-${i+1}`);
    });
  }

  setCurrentState(direction) {

    if (direction.className == 'gallery-controls-previous') {
      this.carouselArray.unshift(this.carouselArray.pop());
    } else {
      this.carouselArray.push(this.carouselArray.shift());
    }
    
    this.updateGallery();
  }

  setControls() {
    this.carouselControls.forEach(control => {
      this.carouselControlsContainer.appendChild(document.createElement('button')).className = `gallery-controls-${control}`;

      document.querySelector(`.gallery-controls-${control}`).innerText = control;
    });
  }

  useControls() {
    const triggers = [...this.carouselControlsContainer.childNodes];

    triggers.forEach(control => {
      control.addEventListener('click', e => {
        e.preventDefault();

        this.setCurrentState(control);
      });
    });
  }
}

//ProductHorizontalImageSliderBlockIds is an array that is created/populated by the js in ProductHorizontalImageSlider.cshtml
let imagesliderexist = (typeof(ProductHorizontalImageSliderBlockIds) !== 'undefined');
if(imagesliderexist)
{
  for (var i = 0; i < ProductHorizontalImageSliderBlockIds.length; i++) { 
    let blockId = ProductHorizontalImageSliderBlockIds[i];
    
    const galleryContainer = document.querySelector('.gallery-container.' + blockId);
    const galleryControlsContainer = document.querySelector('.gallery-controls.' + blockId);
    const galleryControls = ['previous', 'next'];
    const galleryItems = document.querySelectorAll('.gallery-item.' + blockId);

    let carousel = new Carousel(galleryContainer, galleryItems, galleryControls, galleryControlsContainer);
    carousel.setControls();
    carousel.useControls();
  }
}
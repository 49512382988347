import BaseDOMModule from '../core/modules/BaseDOMModule'

export default class Tabs extends BaseDOMModule {
  static domEvents = {
    'click [data-tab-link]': 'onTabLinkClick'
  }

  onTabLinkClick(event, $tabLink) {
    const tabName = $tabLink.data('tab-link')
    this.setActiveTab(tabName)
  }

  setActiveTab(tabName) {
    const { activeTabClass, activeTabLinkClass } = this.options

    const $tabLinks = this.$('[data-tab-link]')
    const $tabContents = this.$('[data-tab-content]')

    $tabLinks.removeClass(activeTabLinkClass)
    $tabContents.removeClass(activeTabClass)

    const $activeTabLink = this.$(`[data-tab-link="${tabName}"]`)
    const $activeTabContent = this.$(`[data-tab-content="${tabName}"]`)

    $activeTabLink.addClass(activeTabLinkClass)
    $activeTabContent.addClass(activeTabClass)
  }
}

export const easeIn = pow => t => Math.pow(t, pow)
export const easeOut = pow => t => 1 - Math.abs(Math.pow(t - 1, pow))
export const easeInOut = pow => t =>
  t < 0.5 ? easeIn(pow)(t * 2) / 2 : easeOut(pow)(t * 2 - 1) / 2 + 0.5

export const linear = easeInOut(1)
export const easeInQuad = easeIn(2)
export const easeOutQuad = easeOut(2)
export const easeInOutQuad = easeInOut(2)
export const easeInCubic = easeIn(3)
export const easeOutCubic = easeOut(3)
export const easeInOutCubic = easeInOut(3)
export const easeInQuart = easeIn(4)
export const easeOutQuart = easeOut(4)
export const easeInOutQuart = easeInOut(4)
export const easeInQuint = easeIn(5)
export const easeOutQuint = easeOut(5)
export const easeInOutQuint = easeInOut(5)

export const swing = t => 0.5 - Math.cos(t * Math.PI) / 2

import BaseService from '../../core/services/BaseService'
import CartService from './CartService'

class HeaderCartService extends BaseService {
  CART_ADD_ITEM = 'header-cart-service.cart.add.item'

  constructor(...args) {
    super(...args)
    CartService.on(CartService.CART_ADD_ITEM, this.onCartItemUpdated.bind(this))
  }

  onCartItemUpdated() {
    this.emit(this.CART_ADD_ITEM)
  }
}

export default new HeaderCartService()

import BaseDOMModule from '../core/modules/BaseDOMModule'
import { $header } from '../services/GlobalDOM'
import { easeOutCubic } from '../core/utils/easings'
import { animateScroll } from '../core/utils/animation'
import Breakpoints from '../constants/Breakpoints'

export default class Contact extends BaseDOMModule {
  static domEvents = {
    'click [data-region-link]': 'onRegionLinkClick',
    'click [data-country-link]': 'onCountryLinkClick'
  }

  static windowEvents = {
    resize: 'onResize'
  }

  animationDuration = 500
  isMobile = true

  onResize(event, windowWidth) {
    this.isMobile = windowWidth < Breakpoints.Medium
  }

  onRegionLinkClick(event, $regionLink) {
    const regionName = $regionLink.data('region-link')
    this.setActiveRegion(regionName)
  }

  onCountryLinkClick(event, $countryLink) {
    const countryLink = $countryLink.data('country-link')
    if (countryLink) {
      window.location.pathname = countryLink
    }
  }

  setActiveRegion(regionName) {
    const { activeRegionClass, activeRegionLinkClass } = this.options

    const $currentActiveRegion = this.$(`.${activeRegionClass}`)
    const $currentActiveRegionLink = this.$(`.${activeRegionLinkClass}`)

    $currentActiveRegion.removeClass(activeRegionClass)
    $currentActiveRegionLink.removeClass(activeRegionLinkClass)

    const wasActive = $currentActiveRegion.is(`[data-region="${regionName}"]`)
    if (wasActive) {
      return
    }

    const $newRegion = this.$(`[data-region="${regionName}"]`)
    const $newRegionLink = this.$(`[data-region-link="${regionName}"]`)

    $newRegion.addClass(activeRegionClass)
    $newRegionLink.addClass(activeRegionLinkClass)

    this.animateRegionScroll($newRegion)
  }

  //setActiveCountry(countryName) {
  //  const { activeCountryClass, activeCountryLinkClass } = this.options

  //  const $currentActiveCountry = this.$(`.${activeCountryClass}`)
  //  const $currentActiveCountryLink = this.$(`.${activeCountryLinkClass}`)

  //  $currentActiveCountry.removeClass(activeCountryClass)
  //  $currentActiveCountryLink.removeClass(activeCountryLinkClass)

  //  const wasActive = $currentActiveCountry.is(
  //    `[data-country="${countryName}"]`
  //  )
  //  if (wasActive) {
  //    return
  //  }

  //  const $newCountry = this.$(`[data-country="${countryName}"]`)
  //  const $newCountryLink = this.$(`[data-country-link="${countryName}"]`)

  //  $newCountry.addClass(activeCountryClass)
  //  $newCountryLink.addClass(activeCountryLinkClass)

  //  this.animateCountryScroll($newCountry)
  //}

  animateRegionScroll($scrollTarget) {
    let headerHeight = $header.height()

    if (!headerHeight) {
      headerHeight = 0
    }

    const scrollPadding = this.isMobile ? 0 : 60
    const scrollTo = $scrollTarget.offset().top - scrollPadding - headerHeight

    animateScroll(scrollTo, this.animationDuration, easeOutCubic)
  }

  //animateCountryScroll($scrollTarget) {
  //  const headerHeight = $header.height()
  //  const scrollPadding = this.isMobile ? 0 : 60
  //  const targetTop = $scrollTarget.offset().top
  //  const scrollToTopTarget = targetTop - headerHeight - scrollPadding

  //  let scrollToTarget = scrollToTopTarget
  //  if (!this.isMobile) {
  //    const targetBottom = targetTop + $scrollTarget.outerHeight()
  //    const viewportHeight = window.innerHeight
  //    const scrollToBottomTarget = targetBottom - viewportHeight + scrollPadding

  //    scrollToTarget = Math.min(scrollToTopTarget, scrollToBottomTarget)
  //  }

  //  animateScroll(scrollToTarget, this.animationDuration, easeOutCubic)
  //}
}

import BaseDOMModule from '../../core/modules/BaseDOMModule'
import CartService from '../../services/commerce/CartService'

export default class HeaderCart extends BaseDOMModule {
  static domEvents = {
    click: 'onClick'
  }

  $countElement = null

  constructor(...args) {
    super(...args)

    CartService.on(CartService.CART_UPDATED, this.onCartUpdate.bind(this))
    CartService.on(CartService.CART_ADD_ITEM, this.onCartItemAdded.bind(this))
    CartService.getCurrentCartState() // Fire and forget - will trigger "CART_UPDATED"

    this.$countElement = this.$element.find('[data-item-count]')
  }

  onCartUpdate(cart) {
    const itemCount = cart.lineItems.reduce(
      (sum, lineItem) => sum + lineItem.quantity,
      0
    )

    if (itemCount > 0) {
      this.$countElement.removeClass('header-cart__count--empty')
    } else {
      this.$countElement.addClass('header-cart__count--empty')
    }

    this.$countElement.text(itemCount)
  }

  onCartItemAdded() {
    this.$countElement.addClass('cart-update-animation')
    this.$element.on(
      'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd',
      event => {
        this.$countElement.removeClass('cart-update-animation')
      }
    )
  }

  onClick(e) {
    e.preventDefault()
  }
}

import BaseDOMModule from '../core/modules/BaseDOMModule'

export default class PrintPage extends BaseDOMModule {
  constructor(...args) {
    super(...args)

    this.initialize()
  }

  initialize() {
    window.print()
  }
}
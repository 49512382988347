export const yearMonthDateFormat = dateObj => {
  const month = dateObj.getMonth() + 1
  const date = dateObj.getDate()
  const year = dateObj.getFullYear()

  const monthString = month < 10 ? `0${month}` : month
  const dateString = date < 10 ? `0${date}` : date

  return `${year}-${monthString}-${dateString}`
}

import BaseTemplateModule from '../core/modules/BaseTemplateModule'
import FilterService from '../services/FilterService'
import ClientData from '../services/ClientData'
import uniqueArr from '../core/utils/fn/uniqueArr'
import { flattenArray, uniqueArray as unique } from 'Core/utils/arrayUtils'

export default class DocumentDownload extends BaseTemplateModule {
  static domEvents = {
    'change [data-select-base-filter]': 'onBaseFilterChange',
    'change [data-select-brand-filter]': 'onBrandFilterChange',
    'change [data-tree-select-filter]': 'onSubfilterChange',
    'click [data-remove-filter]': 'onRemoveFilterClick'
  }

  clientDataKey = 'documentDownload'

  baseFilterLevel = 0
  brandTypeFilterLevel = 1
  currentProductFilterLevel = null // is set runtime
  selectedFilters = []

  $results = this.$('[data-results]')

  constructor(...args) {
    super(...args)
    this.initializeFilters()
  }

  initializeFilters() {
    const url = new URL(window.location.href)

    this.initializeBaseFilter(url)

    this.initializeBrandFilterOptions()
    this.initializeBrandFilter(url)

    this.initializeProductFilter(url)

    this.$results.addClass(this.options.resultsVisibleClass)
    this.initialRender()
  }

  initializeBaseFilter(url) {
    const baseLevel = url.searchParams.get('baseLevel')
    if (baseLevel) {
      const baseLevelElement = document.querySelectorAll(
        '[data-select-base-filter]'
      )[0]

      if (baseLevelElement) {
        baseLevelElement.value = baseLevel
      }
      this.setFilterValue(this.baseFilterLevel, baseLevel)
    }
  }

  initializeBrandFilter(url) {
    const brandValue = url.searchParams.get('brandLevel')
    if (brandValue) {
      const brandFilterElement = document.querySelectorAll(
        '[data-select-brand-filter]'
      )[0]

      if (brandFilterElement) {
        brandFilterElement.value = brandValue
      }

      this.setFilterValue(this.brandTypeFilterLevel, brandValue)
    }
  }

  initializeProductFilter(url) {
    const productParameter = url.searchParams.get('productLevel')
    if (productParameter) {
      const productValues = productParameter.split(',')
      for (let index = 0; index < productValues.length; index++) {
        const value = productValues[index]
        const filter = value.split('|')
        this.setFilterValue(filter[1], filter[0])
      }
    }
  }

  onBaseFilterChange(event, $element) {
    const selectedBase = $element.val()
    this.setFilterValue(this.baseFilterLevel, selectedBase)
    this.setFilterValue(this.brandTypeFilterLevel, "")
    this.renderAll()
  }

  onBrandFilterChange(event, $subFilter) {
    const filterValue = $subFilter.val()
    const filterLevel = this.brandTypeFilterLevel

    this.setFilterValue(filterLevel, filterValue)
  }

  onSubfilterChange(event, $subFilter) {
    const filterValue = $subFilter.val()
    const filterLevel = $subFilter.data('level')

    this.setFilterValue(filterLevel, filterValue)
  }

  onRemoveFilterClick(event, $removeButton) {
    const $filterKey = $removeButton.parent().find('[data-filter-key]')
    const filterKey = $filterKey.data('filter-key').toString()

    const filterLevel = this.selectedFilters.findIndex(x => x === filterKey)

    this.setFilterValue(filterLevel, null)
  }

  setFilterValue(filterLevel, filterValue) {
    this.selectedFilters[filterLevel] = filterValue

    if (filterLevel === 0 && this.selectedFilters[filterLevel + 1] !== null) {
      filterLevel = 1
    }

    const temp = []
    for (var i = filterLevel; i > -1; i--) {
      if (this.selectedFilters[i] !== null) {
        temp[i] = this.selectedFilters[i]
      }
    }

    this.selectedFilters = temp
    this.updateQuearyParameters()
    this.renderAll()
  }

  getSelectedBaseFilter() {
    return this.selectedFilters[this.baseFilterLevel]
  }

  getSelectedBrandFilter() {
    return this.selectedFilters[this.brandTypeFilterLevel]
  }

  getSelectedProductFilter() {
    return this.selectedFilters[this.currentProductFilterLevel]
  }

  getSelectedFilters(withoutBrand = false, withoutBase = false) {
    const copySelectedFilters = [...this.selectedFilters]
    if (withoutBrand) {
      copySelectedFilters[this.brandTypeFilterLevel] = undefined
    }

    if (withoutBase) {
      copySelectedFilters[this.baseFilterLevel] = undefined
    }

    if (this.currentProductFilterLevel) {
      copySelectedFilters[this.currentProductFilterLevel] = undefined
    }

    return copySelectedFilters
  }

  getBrandFilter(assets) {
    if (!assets) {
      return null
    }

    const brandFilters = ClientData.getPageModelData(
      `${this.clientDataKey}.brandFilters`
    )

    const list = brandFilters.filter(filter =>
      assets.some(
        asset =>
          (asset.keywords || []).includes(filter.value) ||
          (asset.keywords || []).includes(`Brand:${filter.value}`)
      )
    )

    return {
      level: this.brandTypeFilterLevel,
      list: list
    }
  }

  getProductFilter(filteredAssets) {
    return filteredAssets.length > 0
      ? [
          {
            level: this.currentProductFilterLevel,
            list: unique(
              flattenArray(
                filteredAssets.map(item =>
                  item.keywords.filter(word => word.startsWith('Product:'))
                )
              )
            ).map(item => ({
              text: item.slice(item.indexOf(':') + 1),
              value: item
            }))
          }
        ]
      : []
  }

  getCurrentTreeLevel() {
    return FilterService.getTreeLevelByFilters(
      FilterService.getDocumentTree(),
      this.getSelectedFilters(true)
    )
  }

  getAssets(tree) {
    if (tree) {
      return [...FilterService.flattenTreeAssets(tree)]
    }
    return null
  }

  getFilteredAssets() {
    const currentLevelTree = this.getCurrentTreeLevel()

    return uniqueArr(
      [
        ...FilterService.flattenTreeAssets(currentLevelTree, entry =>
          this.filterEntry(entry)
        )
      ],
      'name'
    )
  }

  getRootTree() {
    return FilterService.getDocumentTree().subTrees.find(
      x => x.root.key.toString() === this.getSelectedBaseFilter()
    )
  }

  getFilters() {
    const rootTree = this.getRootTree()

    const subFilters = [
      ...FilterService.generateFilters(
        rootTree,
        this.getSelectedFilters(true, true),
        tree => this.isTreeContainBrand(tree),
        2
      )
    ]

    this.currentProductFilterLevel =
      subFilters.length > 0 ? subFilters[subFilters.length - 1].level + 1 : 3

    var currentFilteredAssets = this.getFilteredAssets()
    const productFilters = this.getProductFilter(currentFilteredAssets)  
   
    return [...subFilters, ...productFilters].filter(x => x.list.length > 0)
  }

  isTreeContainBrand(tree) {
    const treeAssets = [
      ...FilterService.flattenTreeAssets(tree, entry => this.filterEntry(entry))
    ]

    return treeAssets.length > 0
  }

  filterEntry(entry) {
    const brandFilterValue = this.getSelectedBrandFilter()
    const productFilterValue = this.getSelectedProductFilter()

    const keywordsContainsBrand = brandFilterValue
      ? (entry.keywords || []).includes(brandFilterValue) ||
        (entry.keywords || []).includes(`Brand:${brandFilterValue}`)
      : true

    const keywordsContainsProduct = productFilterValue
      ? (entry.keywords || []).includes(productFilterValue)
      : true

    return keywordsContainsBrand && keywordsContainsProduct
  }

  updateQuearyParameters() {
    const baseFilter = this.getSelectedBaseFilter()
    const brandFilter = this.getSelectedBrandFilter()
    const productFilterValue = this.selectedFilters.slice(
      2,
      this.selectedFilters.length
    )
    const params = new URLSearchParams(window.location.search)

    let querySection = ''
    if (params.keys.length < 1) {
      querySection = '?'
    }

    if (baseFilter) {
      params.set('baseLevel', baseFilter)
    }

    if (brandFilter) {
      params.set('brandLevel', brandFilter)
    } else {
      params.delete('brandLevel')
    }

    if (productFilterValue.length > 0) {
      params.set('productLevel', this.getProductValues(productFilterValue))
    } else {
      params.delete('productLevel')
    }

    const queryPath = `${
      window.location.pathname
    }${querySection}${decodeURIComponent(params.toString())}`

    window.history.replaceState({}, '', queryPath)
  }

  getProductValues(productFilterValue) {
    var productValue = ''
    for (let index = 0; index < productFilterValue.length; index++) {
      var level = index + 2
      const value = productFilterValue[index]
      if (value) {
        if (productValue !== '') {
          productValue += ','
        }
        productValue += `${value}|${level}`
      }
    }
    return productValue
  }

  initialRender() {
    const brandName = this.options.selectedBrand
    const listItem = document.querySelectorAll('.download-results__item')

    const result = listItem?.length ?? 0

    if (brandName) {
      this.$('[data-base-filter-name]').text(brandName)
    }

    let filterResultText = this.options.filterResultText

    filterResultText = filterResultText.replace(
      '{value}',
      `<span class='tree-filter__results-filter-result-number'>${result}</span>`
    )
    this.$('[data-result-count]').html(filterResultText)
  }

  renderAll() {
    const assets = this.getFilteredAssets()
    const rootTree = this.getRootTree()
    const allAssets = this.getAssets(rootTree)
    const filters = this.getFilters()

    this.renderFilterResults(filters)
    this.renderBrandFilterResults([this.getBrandFilter(allAssets)])
    this.renderAssetsResult(assets)
    this.renderResultCount(assets.length)

    this.initializeBrandFilterOptions()
  }

  renderResultCount(numberOfAssets) {
    const baseFilterKey = this.selectedFilters[this.baseFilterLevel]
    const baseFilterName = FilterService.getDocumentFilterNameByKey(
      baseFilterKey
    )

    const brandFilterName = this.selectedFilters[this.brandTypeFilterLevel]
    let brandName = this.options.selectedBrand

    if (!brandName) {
      brandName = brandFilterName ? brandFilterName : this.options.allCategory
    }

    const filterTitle = `${baseFilterName}  |  ${brandName}`

    let filterResultText = this.options.filterResultText
    filterResultText = filterResultText.replace(
      '{value}',
      `<span class='tree-filter__results-filter-result-number'>${numberOfAssets}</span>`
    )

    this.$('[data-base-filter-name]').text(filterTitle)
    this.$('[data-result-count]').html(filterResultText)
  }

  renderAssetsResult(assets) {
    this.renderItems('documentItem', assets, item => ({
      doc: { _text: item.name, _attr: { href: item.url } },
      url: { _attr: { href: item.url } },
      type: item.mimeType
    }))
  }

  removeOptions(selectElement) {
    var i, L = selectElement.options.length - 1;
    for(i = L; i >= 0; i--) {
       selectElement.remove(i);
    }
  }  

  renderBrandFilterResults(brandFilter){
    const brandFilterElement = document.querySelectorAll(
      '[data-select-brand-filter]'
    )[0]

    if (!brandFilter || brandFilter.length === 0) {
      return
    }

    this.removeOptions(brandFilterElement)  
       
    const empty = document.createElement('option')
    empty.text = this.options.allCategory
    empty.value = ''   
    brandFilterElement.add(empty)

    brandFilter[0].list.forEach(filter => {
      const option = document.createElement('option')
      option.value = filter.value
      option.text = filter.text      
      if(this.selectedFilters[brandFilter[0].level] != null && option.value == this.selectedFilters[brandFilter[0].level]){
        option.selected = true;
      }
      brandFilterElement.add(option)
    })
    brandFilterElement.parentElement.classList.remove(
      'download-filter__container-dropdown--hidden'
    )    
  }  

  initializeBrandFilterOptions() {
    const brandFilterElement = document.querySelectorAll(
      '[data-select-brand-filter]'
    )[0]

    if (brandFilterElement?.options?.length > 0 || this.options.selectedBrand) {
      return
    }

    const rootTree = this.getRootTree()
    const assets = this.getAssets(rootTree)
    const brandFilter = this.options.selectedBrand
      ? { list: [] }
      : this.getBrandFilter(assets)

    if (!brandFilter || brandFilter.length === 0) {
      return
    }

    const empty = document.createElement('option')
    empty.text = this.options.allCategory
    empty.value = ''
    brandFilterElement.add(empty)

    brandFilter.list.forEach(filter => {
      const option = document.createElement('option')
      option.value = filter.value
      option.text = filter.text
      brandFilterElement.add(option)
    })
    brandFilterElement.parentElement.classList.remove(
      'download-filter__container-dropdown--hidden'
    )
  }

  renderFilterResults(filters) {
    const templateElement = this.getTemplate('selectItem').$element.clone()
    const defaultOption = templateElement.find('[data-template-no-remove]')

    this.renderItems('selectItem', filters, filterItem => {
      const selectedFilterValue = this.selectedFilters[filterItem.level]

      let placeholderOptionHTML = defaultOption.prop('outerHTML')
      if (selectedFilterValue && placeholderOptionHTML) {
        placeholderOptionHTML = placeholderOptionHTML.replace(
          /selected(="[^"]*")?/i,
          ''
        )
      }

      const filterOptions = []

      filterOptions.push(
        `<option value="" ${!selectedFilterValue ? 'selected' : ''}>${
          this.options.allCategory
        }</option>`
      )

      filterItem.list.forEach(({ value, text }) =>
        filterOptions.push(
          `<option value="${value}" ${
            value.toString() === selectedFilterValue ? 'selected' : ''
          }>${text}</option>`
        )
      )

      return {
        items: {
          _attr: { 'data-level': filterItem.level },
          _html: [placeholderOptionHTML, ...filterOptions]
        }
      }
    })
  }
}

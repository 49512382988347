import $ from 'jquery'
import BaseDOMModule from '../core/modules/BaseDOMModule'
import Breakpoints from '../constants/Breakpoints'
import { toggleBodyScroll } from '../services/GlobalDOM'

export default class MenuNavigation extends BaseDOMModule {
  static domEvents = {
    'click [data-menu-link]': 'onLinkClick',
    'click [data-menu-back]': 'onBackClick',
    'click [data-menu-sub-back]': 'onBackClick',
    'click [data-menu-close]': 'onCloseClick',
    'click [data-click-overlay]': 'onClickOverlayClick'
  }

  static windowEvents = {
    resize: 'onResize'
  }

  isActive = () => this.$element.hasClass(this.options.menuActiveClass)
  getActiveMenuItems = () => this.$(`.${this.options.itemActiveClass}`)

  $globalHeader = $(this.options.headerSelector)
  $headerHeading = $('[data-menu-header-heading]')
  $backButton = this.$('[data-menu-back]')
  isMobile = true

  onLinkClick(event, $menuLink) {
    const { itemActiveClass } = this.options

    const $menuItem = $menuLink.closest('[data-menu-item]')
    const menuLevel = parseInt($menuItem.data('menu-item'), 10)

    if ($menuItem.hasClass(itemActiveClass) && menuLevel === 1) {
      this.closeMenu()
    } else {
      this.openMenu()
      this.activateMenuItem($menuItem)
    }
  }

  onBackClick() {
    const $activeMenuItems = this.getActiveMenuItems()
    this.deactivateMenuItem($activeMenuItems.last())
    this.setMenuHeading()

    if (this.isMobile === false) {
      this.setMenuHeight($activeMenuItems.last(), true)
    }
  }

  onCloseClick() {
    this.closeMenu()
  }

  onClickOverlayClick() {
    this.closeMenu()
  }

  onResize(event, windowWidth) {
    const wasMobile = this.isMobile
    this.isMobile = windowWidth < Breakpoints.Medium

    if (this.isActive()) {
      if (wasMobile !== this.isMobile) {
        const $activeMenuItems = this.getActiveMenuItems()

        $activeMenuItems.each((_, menuItemEl) => {
          this.activateMenuItem($(menuItemEl))
        })
      }

      if (wasMobile && !this.isMobile) {
        toggleBodyScroll(true)
      }

      if (!wasMobile && this.isMobile) {
        toggleBodyScroll(false)
      }

      const $activeItem = this.getActiveMenuItems().last()
      let $listItem = $activeItem
      let $teasers = $activeItem.find('[data-menu-item-container-teasers]')

      if ($teasers.length === 0) {
        $teasers = $activeItem.siblings('[data-menu-item-container-teasers]')
        $listItem = $activeItem.parent().parent()
      }

      let widthToRight = 0
      if (!this.isMobile) {
        if ($listItem) {
          widthToRight =
            $(window).width() -
            ($listItem.offset().left + $listItem.outerWidth())
        }
        const offsetWidth = 250
        $teasers.children(0).scrollLeft('0')
        $teasers.width(widthToRight - offsetWidth)
        $teasers.parent().width(widthToRight)
      } else {
        const $containers = this.$('[data-menu-item-container]')
        $containers.height('')

        $teasers.width('')
        $teasers.height('')
        $teasers.parent().width('')
      }
    }
  }

  activateMenuItem($menuItem) {
    const { itemActiveClass, listActiveClass } = this.options

    const menuLevel = parseInt($menuItem.data('menu-item'), 10)
    const $sameLevelItems = this.$(`[data-menu-item=${menuLevel}]`)
    const $subMenuItems = $menuItem.find(`[data-menu-item=${menuLevel + 1}]`)
    let $teasers = $menuItem.find('[data-menu-item-container-teasers]')
    if ($teasers.length === 0) {
      $teasers = $menuItem.siblings('[data-menu-item-container-teasers]')
    }

    $sameLevelItems.each((_, menuItemEl) => {
      const $menuItem = $(menuItemEl)
      this.deactivateMenuItem($menuItem)
    })

    $menuItem.addClass(itemActiveClass)
    $menuItem.parent().addClass(listActiveClass)

    if (this.isMobile === false) {
      if (menuLevel === 1 && $subMenuItems.length > 0) {
        this.$backButton.show()
        const $activateSubMenuItem =
          $($subMenuItems[this.options.activeProductIndex]) ||
          $subMenuItems.first()
        this.activateMenuItem($activateSubMenuItem)
      }

      if (menuLevel === 2 || menuLevel === 3) {
        this.setMenuHeight($menuItem)
      }
      $teasers.css('position', 'absolute')
    } else {
      this.$backButton.show()
      if (menuLevel === 2) {
        let totalHeight = 0
        const headerHeight = 50

        $menuItem
          .find('[data-menu-item-container]')
          .children()
          .each(function() {
            totalHeight = totalHeight + $(this).outerHeight(true)
          })

        if (totalHeight > window.screen.height - headerHeight) {
          $teasers.css('position', 'unset')
        }
      }

      $teasers.height('')
      $teasers.width('')
    }

   // this.$backButton.show()
    this.setMenuHeading()
  }

  setTeaserPosition() {}

  setMenuHeight($menuItem, back) {
    const minHeight = 400
    const $containers = this.$('[data-menu-item-container]')
    let $subContainer = null

    $containers.removeAttr('style')

    if (back) {
      $menuItem = $menuItem.parent().parent()
    }

    const menuLevel = parseInt($menuItem.data('menu-item'), 10)
    let $listItem = $menuItem
    let $teasers = $menuItem.find('[data-menu-item-container-teasers]')
    if ($teasers.length === 0) {
      $teasers = $menuItem.siblings('[data-menu-item-container-teasers]')
      $listItem = $menuItem.parent().parent()
    }

    var heights = $menuItem
      .find('[data-menu-item-container]')
      .map(function() {
        return $(this).height()
      })
      .get()

    $teasers.height('')
    if ($teasers?.length > 0) {
      heights.push($teasers.height())
    }

    if (menuLevel === 3) {
      $subContainer = $menuItem.find('[data-menu-item-container-sub]')
      heights.push($subContainer.height())
    }

    var maxHeight = Math.max(...heights)

    if (maxHeight < minHeight) {
      maxHeight = minHeight
    }

    var widthToRight = 0
    if ($listItem) {
      widthToRight =
        $(window).width() - ($listItem.offset().left + $listItem.outerWidth())
    }

    if ($subContainer) {
      $subContainer.height(maxHeight)
    }

    $containers.height(maxHeight)
    $teasers.height(maxHeight)
    $teasers.width(widthToRight - 300)
    $teasers.children(0).scrollLeft('0')
    $teasers.parent().width(widthToRight)
  }

  deactivateMenuItem($menuItem) {
    const { itemActiveClass, listActiveClass } = this.options

    const $subMenuItems = $menuItem.find('[data-menu-item]')

    $menuItem.parent().removeClass(listActiveClass)
    $menuItem.removeClass(itemActiveClass)
    $subMenuItems.removeClass(itemActiveClass)

    const menuLevel = $menuItem.data('menu-item')

    if (menuLevel <= 1) {
      this.$backButton.hide()
    }
  }

  setMenuHeading() {
    const $activeMenuItems = this.getActiveMenuItems()

    const heading =
      $activeMenuItems.length === 0
        ? this.options.defaultMenuHeading
        : $activeMenuItems.last().data('menu-heading')

    this.$headerHeading.text(heading)
  }

  openMenu() {
    if (this.isActive()) {
      return
    }

    const { $element, options } = this
    const { menuActiveClass, menuVisibleClass, headerActiveNavClass } = options

    $element.addClass(menuActiveClass)
    this.$globalHeader.addClass(headerActiveNavClass)

    if (this.isMobile) {
      toggleBodyScroll(false)
    }

    this.setMenuHeading()

    requestAnimationFrame(() => {
      $element.addClass(menuVisibleClass)
    })
  }

  closeMenu() {
    if (!this.isActive()) {
      return
    }

    const { $element, $globalHeader, options } = this

    const {
      menuActiveClass,
      menuVisibleClass,
      itemActiveClass,
      headerActiveNavClass
    } = options

    const $activeMenuItems = this.getActiveMenuItems()

    const disable = () => {
      $element.removeClass(menuActiveClass)
      $activeMenuItems.removeClass(itemActiveClass)
      $globalHeader.removeClass(headerActiveNavClass)
    }

    $element.removeClass(menuVisibleClass)

    if (this.isMobile) {
      $element.on('transitionend', function callback() {
        $element.off('transitionend', callback)
        disable()
      })
      toggleBodyScroll(true)
    } else {
      disable()
    }
  }

  toggleMenu(shouldOpen = !this.isActive()) {
    if (shouldOpen) {
      this.openMenu()
    } else {
      this.closeMenu()
    }
  }
}

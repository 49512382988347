import $ from 'jquery'
import { easeOutQuad } from './easings'

const $window = $(window)

const lerp = (a, b, t) => a + (b - a) * t

export function animateValue(
  from,
  to,
  duration,
  callback,
  easing = easeOutQuad
) {
  let _resolve, _reject
  const promise = new Promise((resolve, reject) => {
    _resolve = resolve
    _reject = reject
  })

  const start = performance.now()
  let frameId = null

  //
  ;(function update() {
    let elapsed = performance.now() - start

    if (elapsed < duration) {
      frameId = requestAnimationFrame(update)

      const t = easing(elapsed / duration)
      const step = lerp(from, to, t)

      callback(step)
    } else {
      callback(to)
      _resolve()
    }
  })()

  promise.cancel = () => {
    cancelAnimationFrame(frameId)
    _reject()
  }

  return promise
}

export function animateScroll(
  to,
  duration,
  easing = easeOutQuad,
  $element = $window
) {
  const from = $element.scrollTop()

  const animationPromise = animateValue(
    from,
    to,
    duration,
    step => {
      $element.scrollTop(step)
    },
    easing
  )

  const scrollInteruptEvents =
    'scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove'
  const onScrollInterupt = event => {
    if (
      event.which > 0 ||
      event.type === 'mousedown' ||
      event.type === 'wheel' ||
      event.type === 'mousewheel' ||
      event.type === 'touchmove'
    ) {
      animationPromise.cancel()
    }
  }

  $element.on(scrollInteruptEvents, onScrollInterupt)

  animationPromise
    .catch(() => {})
    .then(() => {
      $element.off(scrollInteruptEvents, onScrollInterupt)
    })

  return animationPromise
}

export function flattenArray<T>(array: Array<T[]>) {
  return array.reduce(
    (resultArray: T[], itemArray) => [...resultArray, ...itemArray],
    []
  )
}

export function uniqueArray<T>(array: T[]) {
  return [...new Set(array)]
}

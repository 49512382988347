/**
 * Cookies
 * From MDN:
 * 	https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
 */

export const ONE_YEAR = 1000 * 60 * 60 * 24 * 365
export const DEFAULT_PATH = '/'
export const DOMAIN = window.location.name

export function getCookie(cookieKey) {
  if (!cookieKey) {
    return null
  }

  return (
    decodeURIComponent(
      document.cookie.replace(
        new RegExp(
          '(?:(?:^|.*;)\\s*' +
            encodeURIComponent(cookieKey).replace(/[-.+*]/g, '\\$&') +
            '\\s*\\=\\s*([^;]*).*$)|^.*$'
        ),
        '$1'
      )
    ) || null
  )
}

export function setCookie(
  cookieKey,
  cookieValue,
  expires,
  path,
  domain,
  isSecure
) {
  if (
    !cookieKey ||
    /^(?:expires|max-age|path|domain|secure)$/i.test(cookieKey)
  ) {
    return false
  }

  var sExpires = ''
  if (expires) {
    switch (expires.constructor) {
      case Number:
        sExpires =
          expires === Infinity
            ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT'
            : '; max-age=' + expires
        break
      case String:
        sExpires = '; expires=' + expires
        break
      case Date:
        sExpires = '; expires=' + expires.toUTCString()
        break
    }
  }

  document.cookie =
    encodeURIComponent(cookieKey) +
    '=' +
    encodeURIComponent(cookieValue) +
    sExpires +
    (domain ? '; domain=' + domain : '') +
    (path ? '; path=' + path : '') +
    (isSecure ? '; secure' : '')

  return true
}

export function removeCookie(cookieKey, path, domain) {
  if (!hasCookie(cookieKey)) {
    return false
  }

  document.cookie =
    encodeURIComponent(cookieKey) +
    '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
    (domain ? '; domain=' + domain : '') +
    (path ? '; path=' + path : '')

  return true
}

export function hasCookie(cookieKey) {
  if (!cookieKey) {
    return false
  }

  return new RegExp(
    '(?:^|;\\s*)' +
      encodeURIComponent(cookieKey).replace(/[-.+*]/g, '\\$&') +
      '\\s*\\='
  ).test(document.cookie)
}

export function keys() {
  var aKeys = document.cookie
    .replace(/((?:^|\s*;)[^=]+)(?=;|$)|^\s*|\s*(?:=[^;]*)?(?:\1|$)/g, '')
    .split(/\s*(?:=[^;]*)?;\s*/)

  for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) {
    aKeys[nIdx] = decodeURIComponent(aKeys[nIdx])
  }

  return aKeys
}

import $ from 'jquery'
import BaseDOMModule from './BaseDOMModule'
import Console from '../utils/Console'
import { renderDataObjectInElement } from '../utils/templateEngine'

export default class BaseTemplateModule extends BaseDOMModule {
  templates = {}

  constructor(...args) {
    super(...args)
    this.cacheTemplateElements()
  }

  cacheTemplateElements() {
    const $templateElements = this.$('[data-template]')
    $templateElements.each((index, templateElement) => {
      const $templateElement = $(templateElement)
      const $templateParent = $templateElement.parent()

      let templateName = $templateElement.data('template')
      if (!templateElement) {
        templateElement = 'default'
      }

      $templateElement.removeAttr('data-template')
      $templateElement.remove()

      this.templates[templateName] = {
        $element: $templateElement,
        $parent: $templateParent
      }
    })
  }

  getTemplate(templateName) {
    const template = this.templates[templateName]

    if (!template) {
      Console.error(
        `No template with name "${templateName}" found in module "${
          this.moduleName
        }"`
      )
    }

    return template
  }

  renderTemplate(templateName, templateData, clearExisting = false) {
    const template = this.getTemplate(templateName)

    const $templateElement = template.$element.clone()
    const $templateParent = template.$parent

    if (clearExisting === true) {
      $templateParent.empty()
    }

    renderDataObjectInElement($templateElement, templateData)

    $templateParent.append($templateElement)
  }

  renderItems(templateName, items, itemCallback) {
    const { $parent: $templateParent } = this.getTemplate(templateName)

    $templateParent.children(':not([data-template-no-remove])').remove()

    items.forEach((...itemArgs) => {
      const templateData = itemCallback.apply(this, itemArgs)
      if (templateData === null) {
        return
      }
      this.renderTemplate(templateName, templateData)
    })
  }
}

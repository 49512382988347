import $ from 'jquery'
import BaseTemplateModule from '../../core/modules/BaseTemplateModule'
import CartService from '../../services/commerce/CartService'

export default class CartLineItems extends BaseTemplateModule {
  static domEvents = {
    'blur [data-input-quantity]': 'onInputBlur',
    'click [data-update-quantity]': 'onUpdateQuantity',
    'click [data-remove]': 'onRemove'
  }

  inputCachedValueKey = 'inputCachedValue'
  inputHasChangedKey = 'inputHasChanged'

  pendingStatusRemoved = 'removed'
  pendingValues = {}

  constructor(...args) {
    super(...args)

    this.cacheInputQuantities()

    CartService.on(CartService.CART_UPDATED, this.onCartUpdate.bind(this))
  }

  onInputBlur(event, $input) {
    const value = parseInt($input.val(), 10)
    const cachedValue = $input.data(this.inputCachedValueKey)

    if (value !== cachedValue) {
      this.updateItemQuantity($input, value)
    }
  }

  onUpdateQuantity(event, $button) {
    const $item = $button.closest('[data-sku]')
    const $inputQuantity = $item.find('[data-input-quantity]')
    const max = $inputQuantity.attr('max')

    const currentQuantity = parseInt($inputQuantity.val(), 10)
    const buttonDeltaQuantity = parseInt($button.data('update-quantity'), 10)

    const newQuantity = currentQuantity + buttonDeltaQuantity

    if (newQuantity <= max) {
      this.updateItemQuantity($item, newQuantity)
    }
  }

  onCartUpdate(cart) {
    // TODO: Check if cart is different from current values
    this.renderCompleteCart(cart)
  }

  onRemove(event, $target) {
    this.updateItemQuantity($target, 0)
  }

  cacheInputQuantities() {
    this.$('[data-input-quantity]').each((index, input) => {
      $(input).data(this.inputCachedValueKey, parseInt(input.value, 10))
    })
  }

  renderCompleteCart(cart) {
    const { lineItems } = cart

    const getItemRenderData = item => {
      const itemSku = item.code

      const pendingValue = this.pendingValues[itemSku]
      if (pendingValue === this.pendingStatusRemoved) {
        return null
      }

      const itemQuantity = pendingValue || item.quantity

      return {
        _attr: { 'data-sku': item.code },
        name: { _text: item.productName, _attr: { href: item.url } },
        image: { _attr: { src: item.imageUrl } },
        inputQuantity: {
          _attr: {
            value:
              itemQuantity < item.maxQuantity ? itemQuantity : item.maxQuantity
          }
        },
        price: {
          _text: item.totalItemsPlacedPrice,
          _attr: {
            className: item.discountedPrice
              ? 'cart-items__item-price-value--has-discount'
              : ''
          }
        },
        priceDiscount: item.discountedPrice
      }
    }

    this.renderItems('cartItemDesktop', lineItems, getItemRenderData)
    this.renderItems('cartItemMobile', lineItems, getItemRenderData)
  }

  updateItemQuantity($itemOrChild, updatedQuantity) {
    const $item = $itemOrChild.closest('[data-sku]')
    const $input = $item.find('[data-input-quantity]')

    const itemSku = $item.data('sku')

    if (updatedQuantity < 1) {
      this.pendingValues[itemSku] = this.pendingStatusRemoved
      $item.remove()
      CartService.removeItem(itemSku).finally(() => {
        delete this.pendingValues[itemSku]
      })
      return
    }

    if (!updatedQuantity) {
      updatedQuantity = $input.data(this.inputCachedValueKey)
    }

    updatedQuantity = Math.max(1, updatedQuantity)

    $input.val(updatedQuantity)
    $input.data(this.inputCachedValueKey, updatedQuantity)

    this.pendingValues[itemSku] = updatedQuantity

    CartService.updateQuantity(itemSku, updatedQuantity).finally(() => {
      if (this.pendingValues[itemSku] === updatedQuantity) {
        delete this.pendingValues[itemSku]
      }
    })
  }

  getItemSku($itemChild) {
    return $itemChild.closest('[data-sku]').data('sku')
  }
}

import $ from 'jquery'
import BaseDOMModule from '../core/modules/BaseDOMModule'

export default class Tables extends BaseDOMModule {
  static domEvents = {
    'change [data-tables-dropdown]': 'handleSelect',
    'change [data-tables-radio]': 'handleRadio',
    'click [data-tables-print]': 'printTable'
    //'click [data-tables-share]': 'shareTable'
  }

  static windowEvents = {
    resize: 'onWindowResize'
  }

  //shareHash = null
  selectedRadio = null
  selectedOption = null
  $tables = null

  constructor(...args) {
    super(...args)

    this.$tables = this.$('[data-table-name]')

    //this.shareHash = '#' + this.options.shareHash

    this.initialize()
  }

  //getShareParams() {
  //  const search = location.search.substring(1)
  //  let params
  //  try {
  //    params = JSON.parse(
  //      '{"' +
  //        decodeURI(search)
  //          .replace(/"/g, '\\"')
  //          .replace(/&/g, '","')
  //          .replace(/=/g, '":"') +
  //        '"}'
  //    )
  //  } catch (err) {
  //    params = {}
  //  }
  //  return params
  //}

  //getShareUrl() {
  //  const url =
  //    window.location.origin +
  //    window.location.pathname +
  //    '?' +
  //    $.param({ name: this.selectedOption, category: this.selectedRadio }) +
  //    this.shareHash
  //  return url
  //}

  initialize() {
    //if (window.location.hash === this.shareHash) {
    //  // should try to set table filters based on share link
    //  const queryParams = this.getShareParams()

    //  this.selectedOption = queryParams.name
    //  this.selectedRadio = queryParams.category
    //} else {
    this.resetFilters()
    //}

    this.updateDOM()
    this.renderTable(this.selectedOption)
  }

  onWindowResize() {
    this.setStickyHeaderHeight()
  }

  resetFilters() {
    // use first table as initial filters
    const values = this.$tables.data()
    this.selectedOption = values.tableContentid
    this.selectedRadio = values.tableCategory
  }

  handleSelect(ev, el) {
    const val = el[0].value

    this.selectedOption = val
    this.renderTable(this.selectedOption)
  }

  handleRadio(ev, el) {
    const val = el[0].value
    this.selectedRadio = val
    this.selectedOption = this.$tables
      .filter(`[data-table-category="${val}"]`)
      .first()
      .data('table-contentid')

    this.updateDOM()
    this.renderTable(this.selectedOption)
  }

  renderTable(tableId) {
    const activeTableClass = this.options.activeTableClass

    // hide all tables
    this.$tables.parent().removeClass(activeTableClass)

    let table = this.$tables.filter(`[data-table-contentid="${tableId}"]`)

    if (table.length === 0) {
      // failed to find a matching table, default back to first table settings
      table = this.$tables.first()

      this.resetFilters()
      this.updateDOM()
    }

    table.parent().addClass(activeTableClass)
    this.setStickyHeaderHeight()
  }

  setStickyHeaderHeight() {
    const $table = this.$('.' + this.options.activeTableClass)
    const thead = $table.find('thead')

    if (thead) {
      const header = $('.header')
      const headerHeight = header && header[0] ? header[0].clientHeight : 0
      const thChildren = thead?.children()

      if (thChildren && thChildren.length > 0) {
        let height = headerHeight
        thChildren.each(i => {
          const thElements = $(thChildren[i]).children()
          if (i !== 0) {
            const previousChild = thChildren[i - 1]
            height += previousChild.scrollHeight
          }

          this.setDynamicHeaderTop(thElements, height)
        })
      }
    }
  }

  setDynamicHeaderTop(elements, top) {
    $(elements).each(x => {
      elements[x].style.top = `${top}px`
    })
  }

  updateDOM() {
    const $radio = this.$(`[data-tables-radio][value="${this.selectedRadio}"]`)
    const $dropdown = this.$('[data-tables-dropdown]')

    const availableOptions = this.$tables // string[] of markup with table-name
      .filter(
        `[data-table-category="${this.selectedRadio}"], [data-table-category=""]`
      )
      .map((i, item) => {
        const name = $(item).data('table-name')
        const val = $(item).data('table-contentid')
        return `<option value="${val}">${name}</option>`
      })
      .get()

    $dropdown.html(availableOptions)

    $radio.prop('checked', true)
    $dropdown.val(this.selectedOption)
  }

  printTable() {
    const $table = this.$('.' + this.options.activeTableClass)
    const printUrl = this.options.tablePrintUrl
    const contentId = $table.find('[data-table-name]').data('table-contentid')

    let delimiter = '?'
    if (printUrl.includes('?')) {
      delimiter = '&'
    }

    window.open(`${printUrl}${delimiter}tableid=${contentId}`)
  }
}

import $ from 'jquery'
import BaseDOMModule from '../core/modules/BaseDOMModule'

interface BackToTopButtonOptions {
  activeClass: string
}

const scrollThreshold = 250

export default class BackToTopButton extends BaseDOMModule<
  BackToTopButtonOptions
> {
  static domEvents = () => ({
    'click [floater-button]': 'onFloaterClick'
  })

  static windowEvents = {
    scroll: 'onScroll'
  }

  onScroll() {
    if (window.scrollY > scrollThreshold) {
      this.$element.addClass(this.options.activeClass)
    } else {
      this.$element.removeClass(this.options.activeClass)
    }
  }

  onFloaterClick() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}

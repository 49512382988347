import $ from 'jquery'
import BaseDOMModule from '../core/modules/BaseDOMModule'
import { getNumberOfPages } from '../utils/manualCalculations'

export default class ManualFooter extends BaseDOMModule {
  constructor(...args) {
    super(...args)
    this.applyFooterPosition(this)
  }

  applyFooterPosition(target) {
    let viewHeight = '0'
    const onePageViewHeight = 100
    const pages = getNumberOfPages();

    if (pages > 0) {
      viewHeight = `${(pages - 1) * -onePageViewHeight}vh`
    }
    this.$element.css('bottom', viewHeight)
  }
}

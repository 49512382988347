import BaseTemplateModule from '../core/modules/BaseTemplateModule'
import SearchService from '../services/SearchService'

export default class Search extends BaseTemplateModule {
  static domEvents = {
    'input [data-search-input]': 'onSearchInput',
    'change [data-brand-filter]': 'onSearchInput',
    'change [data-category-filter]': 'onSearchInput',
    'click [data-next-page]': 'onNextPageClick'
  }

  currentSearchRequest = null
  $searchInput = this.$('[data-search-input]')

  pageNumber = this.options.pagenumber || 0
  pageSize = this.options.pagesize || 20
  concatResultItems = []

  onSearchInput() {
    const searchValue = this.$searchInput.val()

    const brandFilterIds = this.getBrandFilterIds()
    const catFilterIds = this.getCategoryFilterIds()

    this.toggleSearchResultsContainer(true)
    this.search(searchValue, brandFilterIds, catFilterIds)
  }

  async search(searchValue, brandIds, catIds, clearResult = true) {
    this.toggleNoResultsFound(false)
    this.toggleLoading(true)

    if (this.currentSearchRequest) {
      this.currentSearchRequest.abort()
    }

    const request = (this.currentSearchRequest = SearchService.search(
      searchValue,
      brandIds,
      catIds,
      this.pageNumber,
      this.pageSize
    ))
    const response = await request.catch(() => ({ items: [] }))

    if (request !== this.currentSearchRequest) {
      // Another search was started before we received result
      return
    }

    this.toggleLoading(false)

    if (clearResult) {
      this.clearResult()
    }

    this.concatResultItems = [...this.concatResultItems, ...response.items]

    if (!response.items || response.items.length === 0) {
      this.toggleNoResultsFound(true)
    } else {
      this.renderResult(this.concatResultItems)
      this.renderTotalCount(response.totalCount)
    }

    if (response.items.length < this.pageSize) {
      this.toggleNextButton(false)
    } else {
      this.toggleNextButton(true)
    }
  }

  onNextPageClick() {
    this.pageNumber = this.pageNumber + 1
    this.search(
      this.$searchInput.val(),
      this.getBrandFilterIds(),
      this.getCategoryFilterIds(),
      false
    )
  }

  toggleSearchResultsContainer(shouldShow) {
    this.toggle$ElementClass(
      this.options.resultContainerVisibleClass,
      shouldShow === true
    )
  }

  toggleNoResultsFound(shouldShow) {
    if (shouldShow === true) {
      this.$element.removeClass(this.options.hasResultsClass)
    }

    this.toggle$ElementClass(this.options.noResultsClass, shouldShow === true)
  }

  toggleLoading(isLoading) {
    this.toggle$ElementClass(this.options.loadingClass, isLoading === true)
  }

  toggle$ElementClass(className, shouldApply) {
    if (className) {
      this.$element.toggleClass(className, shouldApply === true)
    }
  }

  toggleNextButton(shouldShow) {
    if (shouldShow) {
      this.$('[data-next-page]').show()
    } else {
      this.$('[data-next-page]').hide()
    }
  }

  clearResult() {
    this.$element.removeClass(this.options.hasResultsClass)
    this.concatResultItems = []
    this.pageNumber = 0
    this.renderItems('searchResultItem', [])
    this.renderTotalCount(0)
  }

  renderResult(resultItems) {
    this.$element.addClass(this.options.hasResultsClass)

    this.renderItems('searchResultItem', resultItems, item => ({
      link: {
        _text: item.title,
        _attr: { href: item.url }
      }
    }))
  }

  /**
   * Override to define behaviour
   */
  renderTotalCount(totalCount) {}

  getBrandFilterIds() {
    return this.getFiltervalues('data-brand-filter')
  }

  getCategoryFilterIds() {
    return this.getFiltervalues('data-category-filter')
  }

  // fetch filter values from checkboxes or radio buttons
  getFiltervalues(dataAttribute) {
    const $filterItems = this.$('[' + dataAttribute + ']:checked')
    const values = $filterItems.map((_, element) => element.value).toArray()
    return values
  }
}
